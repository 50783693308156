import React from 'react';
import ReactDOM from 'react-dom';

import { TaskListView } from './TaskListView'; 
import { gapi } from 'gapi-script';
import { getTeamUsers, getTaskList, getUrlParameter2 }  from '../utils';

import "antd/dist/antd.css";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export var TEAM_MEMBERS = {}; 
export var memberTaskList = []; 
export var TEAM_FOLDER_ID = "";
/*
    Append all the tasks that the current user is associated with. 
*/
function showTaskList(userID, folderID=TEAM_FOLDER_ID) {
    getTaskList(userID)
        .then( e => {
            
        if(TEAM_FOLDER_ID){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'grant_type': 'refresh_token',
                    'client_id': process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
                    'client_secret': process.env.REACT_APP_CLIENT_SECRET,
                    'refresh_token': process.env.REACT_APP_REFRESH_TOKEN
                })
            };
    
            // get access token
            fetch('https://www.googleapis.com/oauth2/v4/token', requestOptions)
                .then(response => response.json())
                .then(data => {
                    
                    // get file list from group folder
                    fetch(`https://www.googleapis.com/drive/v2/files?access_token=${data["access_token"]}&q='${folderID}'%20in%20parents`)
                        .then(response => response.json())
                        .then(data => {
                            console.log(data.items)
                            ReactDOM.unmountComponentAtNode(document.getElementById('tasklistview'));
                            
                            ReactDOM.render(
                            <TaskListView tasks={e} userId={userID} userMap={TEAM_MEMBERS} files={data.items || []}/>,
                                document.getElementById('tasklistview')
                            );  
                        })
                });
        } else {    // TaskLighter
            ReactDOM.unmountComponentAtNode(document.getElementById('tasklistview'));
            
            ReactDOM.render(
            <TaskListView tasks={e} userId={userID} userMap={TEAM_MEMBERS} files={[]} taskLighter={true}/>,
                document.getElementById('tasklistview')
            );  
        }
        
    }, console.log);
}

const loadUserTaskList = (e) => {
    showTaskList(e["key"], e["folderID"]); // key = userId 
    document.getElementById("initial").innerHTML = TEAM_MEMBERS[e["key"]].name[0]; 
}

class TaskListDropdown extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {loaded: false}; 
    }

    /** older method by Soya
     *  works for parsing urls like `?team=[team name]`
     */
    getUrlParameter = (sParam) => {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
    
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
    
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    

    async componentDidMount() {
        // TODO get team name from link parameter
        const team_name = getUrlParameter2("team");
        if(!team_name) {
            alert("Wrong URL. Contact admin to get a right url");
            return;
        }
        
        await getTeamUsers(team_name).then( users => {
            users.forEach((user) => {
                TEAM_MEMBERS[user.id] = user.data();
                this.setState({loaded: true}); 
            });
            
            var userId;

            for (var key in TEAM_MEMBERS) {
                // check if the property/key is defined in the object itself, not in parent
                if (TEAM_MEMBERS[key].email === this.props.email) {      
                    userId = key;      
                }

                // one of team members has folderID
                if(TEAM_MEMBERS[key].folderID) 
                    TEAM_FOLDER_ID = TEAM_MEMBERS[key].folderID;
            }


            if (userId){
                // load user list with corresponding user ID of this.props.email
                loadUserTaskList({key: userId, folderID: TEAM_FOLDER_ID || null})
            }
            else {
                alert(`Your email address is not registered for team ${team_name}. Contact admin to sign up to the team.`);
            }
        }, console.log);
    }
    render() {
        if (this.state.loaded && Object.keys(TEAM_MEMBERS).length !== 0) {
            const menu = (
                <Menu onClick={loadUserTaskList}>
                    {Object.keys(TEAM_MEMBERS).map((id) => 
                        <Menu.Item key={id}>
                            {TEAM_MEMBERS[id].name}
                        </Menu.Item>
                    )}
                </Menu>
            );
            return (
                <div id="taskDropdown" style={{display:"none"}}>
                <Dropdown overlay={menu}>
                    <button className="ant-dropdown-link" style={{fontSize: 'x-large', border: 'none', background: 'none', color: 'white'}} onClick={e => e.preventDefault()}>
                    Choose Task List <DownOutlined />
                    </button>
                </Dropdown>
                </div>
            )
        } else {
            return (<h1>Loading...</h1>)
        }
    }
}

export {TaskListDropdown, showTaskList}
