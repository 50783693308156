import React from 'react';
import {
  BrowserRouter as Router, 
  Switch,
  Route, 
} from 'react-router-dom';
import "./lib/bootstrap.css"; 
import './App.css';


import { LandingPage } from './components/LandingPage';
import TaskLightHome from './components/TaskLightHome';
import { PrivacyPage } from './components/PrivacyPage';

class App extends React.Component {
  constructor(props) {
    super(props); 
    this.props = props; 
    this.state = {
        teamName: ''
    }
  }

  onChange = (e) => {
      this.setState({teamName: e.target.value}); 
  }

  render() {
    return (
      <div>
          <Router>
              <Switch>
                <Route path={'/team/' + this.state.teamName}><TaskLightHome /></Route> {/* TaskLight Home Page */}
                <Route exact path='/'> {/* Landing Page */}
                  <div style={{height: "100%"}}>
                  <LandingPage onChange={this.onChange} teamName={this.state.teamName} />
                  </div>
                </Route>
                <Route exact path='/privacy/'><PrivacyPage/></Route> {/* Privacy policy Page */}
              </Switch>
          </Router>
      </div>
    )
              }
}

export default App;