import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import '../App.css';
import { Layout, Menu, Input, Row, Col } from 'antd';
const { Header, Content, Footer } = Layout;

function removeTaskListView() {
    if(document.getElementById('taskListView')) {
      ReactDOM.unmountComponentAtNode(document.getElementById('tasklistview'));
    }
    if(document.getElementById('tasklistview')) {
        document.getElementById('tasklistview').style.display = 'none'; 
    }
}

class LandingPage extends React.Component {
    constructor(props) {
        super(props); 
        this.props = props; 
    }

    render() {
        removeTaskListView(); 
        return (
            <Layout className="layout">
                <Header style={{backgroundColor: 'white', position: 'fixed', width: '100%'}}>
                <Menu mode="horizontal" onClick={(e) => document.getElementById(e.key).scrollIntoView()}>
                    <Menu.Item className="landing-page-menu" key={"about"}>{`About`}</Menu.Item>
                    <Menu.Item className="landing-page-menu" key={"how-it-works"}>{`How it works`}</Menu.Item>
                    <Menu.Item className="landing-page-menu" key={"how-to-join"}>{`How to join`}</Menu.Item>
                    <Menu.Item className="landing-page-menu" key={"research-team"}>{`Research Team`}</Menu.Item>
                </Menu>
                </Header>
                <div id="intro" class="landing-page" style={{backgroundColor: "#faeab4"}}>
                    <div>
                        <img 
                            src={window.location.origin + '/tasklight-logo.png'} 
                            width="350"
                            style={{marginTop: "50px"}}
                        /> 
                        <h3>Rethinking Collaborative Task Management</h3>
                        Enter your team name to continue to TaskLight
                    </div> 
                    <div className="site-layout-content">
                        <Input placeholder="team name" onChange={this.props.onChange} style={{width: "200px"}}/>
                        <Link to={"/team/" + this.props.teamName} className="btn btn-primary" style={{margin: "10px"}}>Go to TaskLight</Link>
                    </div>
                    Don't have a team? Email <a href = "mailto: soya@mit.edu">soya@mit.edu</a> to register a team. 
                    <br /><br />
                </div>
                <div id="about" class="landing-page" style={{backgroundColor: "white"}}>
                    <br />
                    <h3>What is TaskLight?</h3>
                    People rely on others to get things done. They ask each other for help on things we call <b class="keywords">tasks</b>. <br /><br />
                    But asking for help and providing help is not always easy. Collaborating with others to accomplish something can be mentally demanding, stressful, and increasingly complex as more individuals become involved. <br /><br />
                    We sought ways to make collaborative task management easier for people. And so, we designed TaskLight, a groupware system that allows people to <b class="keywords">request and manage help in collaborative settings.</b>
                    <br /><br />
                </div>
                <div id="how-it-works" class="landing-page" style={{backgroundColor: "#f0f2f5"}}>
                    <br />
                    <h3>How does it work?</h3>
                    TaskLight operates based on <b class="keywords">requester-performer relationships</b> among team colleagues, where requesters ask performers to fulfill some task. <br /><br />

                    {/* TaskLight provides structured guidance for requesters to request help to their performers and for performers to easily digest the request and voice their opinions on the request. TaskLight supports this in 3 main ways. <br /><br /> */}

                    TaskLight has 3 main functions: <br /><br />

                    <ol>
                        <li style={{display: 'list-item', listStyleType: 'number'}}>
                        <b class="keywords">Easing task authoring</b>: TaskLight recommends useful default fields to requesters, so they can send requests to performers more easily. 
                        </li>
                        <li style={{display: 'list-item', listStyleType: 'number'}}>
                        <b class="keywords">Supporting task consolidation</b>: TaskLight easily connects with shared objects (eg. Google Drive) to make requests feel less fragmented. 
                        </li>
                        <li style={{display: 'list-item', listStyleType: 'number'}}>
                        <b class="keywords">Humanizing task management</b>: TaskLight alllows requesters and performers to engage in collaborative task construction, which offers room for adjustment and negotiation surrounding requests.
                        </li>
                    </ol>

                    Watch the video below to see a demo of TaskLight! 
                    <br /><br />
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/UoTPi6ZDpbw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>

                    <br /><br />
                </div>
                <div id="how-to-join" class="landing-page" style={{backgroundColor: "white"}}>
                    <br />
                    <h3>How can you join?</h3>
                    We are always looking for new people to try our tool! To get started with TaskLight, email <a href = "mailto: soya@mit.edu">soya@mit.edu</a> to register your team today. 
                    <br /><br />
                </div>
                <div id="research-team" class="landing-page" style={{backgroundColor: "#f0f2f5"}}>
                    <br />
                    <h3>Our research team</h3>
                    TaskLight is a research project built by the <a href="http://haystack.csail.mit.edu/" target="_blank">Haystack Group</a> at MIT CSAIL. 
                    <br /><br />
                    <Row justify="space-between">
                        <Col span={4} style={{maxWidth: '25%'}}>
                            <img src={window.location.origin + '/teamPics/karger.png'} class="img-circle" width="200"></img><br />
                            <div class="teamInfo">
                                David Karger<br />
                                <a href="https://twitter.com/karger">
                                    <img src={window.location.origin + '/twitter.png'} width="40"></img>
                                    @karger
                                </a><br />
                                <a href="mailto:karger@mit.edu">karger@mit.edu</a><br />
                            </div>
                        </Col>
                        <Col span={4} style={{maxWidth: '25%'}}>
                            <img src={window.location.origin + '/teamPics/michaelMuller.jpeg'} class="img-circle" width="200"></img>
                            <div class="teamInfo">
                                Michael Muller<br />
                                <a href="https://twitter.com/michael_muller">
                                    <img src={window.location.origin + '/twitter.png'} width="40"></img>
                                    @michael_muller
                                </a><br />
                                <a href="mailto:michael_muller@us.ibm.com ">michael_muller@us.ibm.com </a><br />
                            </div>
                        </Col>
                        <Col span={4} style={{maxWidth: '25%'}}>
                            <img src={window.location.origin + '/teamPics/soya.jpeg'} class="img-circle" width="200"></img>
                            <div class="teamInfo">
                                Soya Park<br />
                                <a href="https://twitter.com/park_soya">
                                    <img src={window.location.origin + '/twitter.png'} width="40"></img>
                                    @park_soya
                                </a><br />
                                <a href="mailto:soya@mit.edu">soya@mit.edu</a><br />
                            </div>
                        </Col>
                        <Col span={4} style={{maxWidth: '25%'}}>
                            <img src={window.location.origin + '/teamPics/svishwab.jpeg'} class="img-circle" width="200"></img>
                            <div class="teamInfo">
                                Stuti Vishwabhan<br />
                                <a href="https://twitter.com/stutivish">
                                    <img src={window.location.origin + '/twitter.png'} width="40"></img>
                                    @stutivish
                                </a><br />
                                <a href="mailto:svishwab@mit.edu">svishwab@mit.edu</a><br />
                            </div>
                        </Col>
                    </Row>
                    <div style={{height: "200px"}}></div> 
                    <br /><br />
                </div>
            </Layout>
        )
    }
}

export {LandingPage};
