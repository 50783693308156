import React from 'react';
import ReactDOM from 'react-dom';

import emailjs from 'emailjs-com';

import "antd/dist/antd.css";
import '../task.css';

import { Button, Cascader, Col, Empty, Form, Input, DatePicker, Divider, notification, Popover, Row, Select, Tag, Tree, Alert, Tooltip } from 'antd';
import { ExportOutlined, FileOutlined, FilePptOutlined, FolderOpenOutlined, MinusCircleTwoTone, QuestionCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';

import _ from "lodash";
import {ChatWrapper, changeTaskStatus, changeTaskWorkStatus, createData, createTask, convertToFriendlyDate, deleteField, deleteTask, getTask, getBackgroundColor, getUrlParameter2, getSuggestion, insertQuote, loadTask, statusPrompt, STATUS, setData, reloadTaskList, getTaskList}  from '../utils';

import { gapi } from 'gapi-script';
import {memberTaskList} from './TaskListDropdown'; 

import {ActionItems} from './ActionItems'; 
import {TodoListItem, TodoApp} from './ActionItems2'; 


const { Option } = Select;
const { TextArea } = Input;

class TaskView extends React.Component {
	constructor(props) {
		super(props);
		this.props = props;
		// debugger;

		this.timeout =  0;
		this.location_timeout = 0;
		this.dateFormat = 'YYYY/MM/DD';
		
		this.state  = {...this.props, ...{
			reverse_users_list: {}, // name -> id
			performers: Object.keys(this.props.performers || {}).filter(id => !this.props.requesters.includes(id)) || [], 	// local copy of performer *list*
			status: this.props.status || "DRAFTING",
			workStatus: this.props.workStatus || "DRAFTING",

			// this copy the entire list of subtasks even including the subtasks that is not visible to this user. This will be used when the user try to modify subtasks to DB
			subtask_cp: this.props.what ? [...this.props.what]: [], 
			requester_names: this.props.requesters.map(r => this.props.userMap[r].name),
			suggestion: {"suggested_by": {}},
			suggestion_request: {}, // list of suggestion request by requesters; key: field name
			visiblePopoverField: null,
			visibleHelperField: null,
			visible: false,
			deadline_limit: null,
			file_options: [], // for location cascader
			treeData: [], 
			otherTasks: [],
			similarTasks: [], 
			deleteDisabled: false,
			location_categories: [...new Set( [...["Description", "Location of work"], ...this.props.location ? this.props.location.filter(l => l.category).map(l => l.category):[]] )], 
			location_category: null,
			location_search: [],
			// [field + "_show"]: true // state to track which field should show edit field; used when there is no suggestion or value but users want to see the field
			// [field + "_edit"]: true // state to track which field should be in edit mode; used for performere's view 
		}};		

		this.state.actionItems = [{children: this.state.actionItems?.length > 1  ? this.state.actionItems[0].children : [], key: "private", title: <span>👁️ only visible to you: not ready or too personal to share with {this.state.requester_names}</span>}, 
			{children:this.state.actionItems?.length > 1 ? this.state.actionItems[1].children : [], key: "public", title: <span>🙋 visible to others: this would help {this.state.requester_names} to know that you have blockers, so they can plan out their request</span>}];

		this.saveChanges = this.saveChanges.bind(this);
		console.log('props: ', this.props); 
		console.log('state: ', this.state); 

		// Load suggestion 
		getSuggestion(this.props.taskID).then( suggest => {
			let s = suggest.data();
			if(s) {
				let suggestion_state = {"suggestion": s};
				let suggestion_request = {...this.state.suggestion_request}

				Object.keys(s).map(key => {
					if(s[key])	//suggestion made by performer
						suggestion_state[key + "_change"] = true; 
					else 		//suggestion requested by requester
						suggestion_request[key] = true; 
					return null;
				});
				
				suggestion_state["suggestion_request"] = suggestion_request;
				this.setState(suggestion_state);
			}
			
		}, alert);



		Object.entries(this.props.userMap).map(([id, v]) => {
			return this.state.reverse_users_list[v.name] = id;
		});

		// Load sub-tasks
		if(this.state.actionItems?.length == 2 && (this.state.actionItems[0].children.length + this.state.actionItems[1].children.length > 0)) {
			this.state.deleteDisabled = true; // even if there is no visible subtask for the current user, it means there are some subtasks 

			Promise.all(
				[...this.state.actionItems[0].children, ...this.state.actionItems[1].children].map(w => getTask(w["key"]))
			).then(subtasks => {
				let sa = _.cloneDeep(this.state.actionItems);
				
				subtasks.map((o, i) => {
					const private_ids = sa[0].children.map(p => p.key);
					const actionItemPost = private_ids.includes(o.id) ? 0 : 1;
					const doc_index = sa[
						actionItemPost
					].children.findIndex(x => x.key === o.id);

					let itemList = _.cloneDeep(sa[
						actionItemPost
					].children[doc_index]);

					let status_list = []
					for (const [key, p] of Object.entries("performers" in o.data() ? o.data()["performers"]:{})) {
						status_list.push(p['status']);
					}

					itemList = {
						...itemList, 
						...{
							title: o.data()['title'], 
							"requesters": o.data()["requesters"],
							"performers": o.data()["performers"],
							status:	status_list.includes("WAITING_DRAFT") || status_list.length == 0?  "WAITING_DRAFT" : status_list[0]
						}
					};


					sa[
						actionItemPost
					].children[doc_index] = itemList;

					console.log(sa[
						actionItemPost
					].children[doc_index])
				})

				this.setState({"actionItems": sa});
				console.log(sa);
			})
		}

		if(this.state.why && "task-id" in this.state.why)
			getTask(this.state.why["task-id"]).then( snapshot => {
				let task = snapshot.data();
				let depending_task_date = null;
				if (task.deadline) {
					let [y,m,d] = task.deadline.split("-")
					depending_task_date = new Date(y, m - 1, d).setHours(23,59,59,999);
				}
				this.setState({"why": {
									"value": task.title, 
									"task": true, 
									"task-id": this.state.why["task-id"], 
									"performers": task.performers, 
									"requesters": task.requesters
								}, 
								"deadline_limit": depending_task_date})
			}, alert);




		// helper for location; set up related team documents
		this.state.file_options.push(...this.props.files.map(function(f) {
			return {
				"label": f.title || "[No name]",
				"link": f.alternateLink,
				"value": f.id
			};
		}))	

		this.formatTreedata(this.state.file_options);
		
		

		emailjs.init("user_jzbkzTRjGnVizvlvvPsZG");

		// polling resources only if it is TL, not TaskLighter
		if(!this.props.taskLighter) {
			console.log("polling");
			const self = this;
			this.pollCnt = 0;

			// call initially
			self.initFiles();

			this.intervalId = window.setInterval(function(){
				/// poll updates in files 
				self.pollCnt = self.pollCnt + 1;
				self.initFiles();
	
				// if users stay in this page too long, assume they are idle and stop polling 
				if( self.pollCnt > 20 ){
					clearInterval(self.intervalId);
					self.intervalId = false;
				}
					
			  }, 4000);
		}	
	}

	componentWillUnmount() {
		if(this.intervalId) {
			clearInterval(this.intervalId);
			console.log("stop polling");
		}
			
	}
	
	componentDidMount() {
		if(this.state.requester_names.length === 1) {
			let selector_remover = document.querySelector(".task-title-container .ant-select-selection-item-remove");
			if(selector_remover)
				selector_remover.style.display = "none";
		}

		// handle style error for action items field for stakeholders
		if(!this.props.taskLighter && !this.props.is_performer && !this.state.requesters.includes(this.props.userID)) {
			document.querySelector('.ant-tree-list-holder-inner').style.width = "100%";
		}
		
		var element = document.getElementById("taskview");
		window.scrollTo({
			top: element.offsetTop,
			// behavior: 'smooth'
		});

		this.getSimilarDeadlineTask("");
		this.getSimilarTimedTask(this.state.anticipated_time, this.state.performers); 

		// if the message was sent by counterpart, mark this message read as they opened the task
		if(this.state.status == "HAVE_CHAT")
			// only update DB and don't update state so it won't re-render the status message at this time
			changeTaskStatus(this.props.taskID, this.props.performer, this.state.workStatus);
			
	}

	componentDidUpdate(prevProps, prevState) {
		Object.entries(this.props).forEach(([key, val]) =>
		  prevProps[key] !== val && console.log(`Prop '${key}' changed`)
		);
		if (this.state) {
		  Object.entries(this.state).forEach(([key, val]) =>
			prevState[key] !== val && console.log(`State '${key}' changed`, val) 
		  );
		}
	  }

	initFiles() {
		console.log("new file");
		const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'grant_type': 'refresh_token',
                'client_id': process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
                'client_secret': process.env.REACT_APP_CLIENT_SECRET,
                'refresh_token': process.env.REACT_APP_REFRESH_TOKEN
            })
		};
		
        fetch('https://www.googleapis.com/oauth2/v4/token', requestOptions)
            .then(response => response.json())
            .then((data) => {
				console.log(data)

				Promise.all(
					this.state.file_options.filter(fo => fo.link.includes("document")).map(fo => 
						this.loadDocStructure(fo.value, this.state.file_options.indexOf(fo), data["access_token"], this.props.location?.length && (fo.value == this.props.location[0])))
				).then(subtasks => {
					this.setState({file_options: _.cloneDeep(this.state.file_options), treeData: this.state.treeData});
				})
				
            });
	}

	processTreeData = (treeData) => {
		let s = _.cloneDeep(treeData);
		s[0].title="private"
		s[1].title="public";

		s[0].children = s[0].children.map(i => {
			let c = _.cloneDeep(i);
			if(c.test)
			c.title = c.test;
			return c;})
		s[1].children = s[1].children.map(i => {
			let c = _.cloneDeep(i);
			if(c.test)
				c.title = c.test;

			return c;})

		
		return s;
	}

	addSubTask = (title, actionitemIndex=null, location=null) => {
		let copy_performers = {};
		for (const p of this.state.performers){
			if(p == this.props.userID) continue
			copy_performers[p] = {"priority": "", "status": "WAITING_DRAFT", "workStatus": "WAITING_DRAFT"};
		}

		createTask(this.props.userID, {
			"title": title, 
			performers: copy_performers,
			"location": location || [], 
			"stakeholders": this.props.is_performer? [] :[this.props.userID], 
			"why": {"task-id": this.props.taskID, "value": this.state.title ? this.state.title : ""}}).then( async task => {    

			let tree_db = this.processTreeData(this.state.actionItems);

			// re-render the action item fields using only subtasks that are visible to the usuer
			// let items = this.state.actionItems?.length ? [...this.state.publicItems] : [];  
			let t = _.cloneDeep(tree_db[1].children);
			t.push({ 
				status: "WAITING_DRAFT",
				requesters: [this.props.userID], 
				performers: copy_performers, 
				title: title, 
				key: task.id, 
				children: []});

			tree_db[1].children = t;

			await setData({"actionItems":tree_db}, "tasks", this.props.taskID);
			
			tree_db[0].title = <span>👁️ only visible to you: not ready or too personal to share with {this.state.requester_names}</span>;
			tree_db[1].title = <span>🙋 visible to others: this would help {this.state.requester_names} to know that you have blockers, so they can plan out their request</span>;
			
			const tt = _.cloneDeep(tree_db)

			tt[1].children.map(c => {
				
				if(!("status" in c)){
					console.log(c);
					throw "Assertion failed";
				}
					
				return;
			}
			)

			this.setState((prevState) => {
				let temp = {
				   ...prevState,
				   deleteDisabled: true, 
				   actionItems: [...prevState.actionItems]
				}
	 
				// change title "Soldier of fortune" to "Child in time"
				temp.actionItems[1].children.push({ 
					status: "WAITING_DRAFT",
					requesters: [this.props.userID], 
					performers: copy_performers, 
					title: title, 
					key: task.id, 
					children: []});
	 
				return temp
			 })

		}, alert);
	}

	deleteSubTask = (taskID) => {
		deleteTask(taskID).then( task => {   
			// delete the subtask from cp and save it to db
			let tree_db = this.processTreeData(this.state.actionItems);
			tree_db[0].children = tree_db[0].children.filter(t => t.key !== taskID);
			tree_db[1].children = tree_db[1].children.filter(t => t.key !== taskID);

			setData({"actionItems":tree_db}, "tasks", this.props.taskID);

			tree_db[0].title = <span>👁️ only visible to you: not ready or too personal to share with {this.state.requester_names}</span>;
			tree_db[1].title = <span>🙋 visible to others: this would help {this.state.requester_names} to know that you have blockers, so they can plan out their request</span>;
			this.setState({"actionItems": tree_db, deleteDisabled: tree_db[0].children.length + tree_db[1].children.length, what_show: true});
			
		}, alert);
	}

	moveSubtask = (tasks, re_render=false) => {
		let tree_db = this.processTreeData(tasks);
		setData({"actionItems": tree_db}, "tasks", this.props.taskID);
		
		tree_db[0].title = <span>👁️ only visible to you: not ready or too personal to share with {this.state.requester_names}</span>;
		tree_db[1].title = <span>🙋 visible to others: this would help {this.state.requester_names} to know that you have blockers, so they can plan out their request</span>;

		this.setState({"actionItems": tree_db})
	}
	
	// create a new google doc in team shared folder
	create_new_document = () =>  {
		var name = document.querySelector(".ant-cascader-picker input").value;
		notification.success({
			message: `Done!`,
			description:
			  <span>Created a new document <a href="h">{name}</a></span>,
			  placement: 'bottomRight',
		  });

		// put the new document in a cascader option
		let new_option = [...this.state.file_options];
		new_option.push({"value": name, "label": name});
		this.setState({"file_options": new_option})

		this.saveChanges([name], "location");
	}

	create_new_suggestion = (field, new_data=null) => {
		if(this.props.is_performer) {
			createData({[field]: new_data, "suggested_by": {[field]: this.props.userID}}, "suggestion", this.props.taskID);
		} else {
			let s=this.state.suggestion_request || {};
			s[field] = true;
			this.setState({"visible": false, visiblePopoverField: null, "suggestion_request": s});

			createData({[field]: "", "suggested_by": {[field]: this.props.userID}}, "suggestion", this.props.taskID);
		}

		this.askClarification();
	}

	deleteTask() {
		const deleteAndReloadTask = () => deleteTask(this.props.taskID).then( task => {       
			// window.scrollTo({
			// 	top: 0,
			// 	behavior: 'smooth'
			// });
			reloadTaskList(this.props.userID); 

            // detach the previous task 
            if(document.getElementById('contentliquid'))
                ReactDOM.unmountComponentAtNode(document.getElementById('taskview'));
    
		}, alert);
		
		if (this.state.why && "task-id" in this.state.why) {
			// delete this task from its parent 
			// words.filter(word => word.length > 6);
			getTask(this.state.why["task-id"]).then( async snapshot => {
				const parentTasks = snapshot.data().actionItems;
				let new_actionItems = [];

				for (const items of parentTasks) {
					new_actionItems.push({
						...items,
						children: _.cloneDeep(items.children.filter(subtask => subtask["key"] !== this.props.taskID))
					})
				  }

				  console.log(new_actionItems[1]);
				  debugger;
				await setData({"actionItems": new_actionItems}, "tasks", this.state.why["task-id"]);

				deleteAndReloadTask();
			});
		}

		else deleteAndReloadTask();
		
	}

	disabledDate = (current) =>  {
		// Can not select days before today and today
		if (this.state.deadline_limit) 
			return (current && current < new Date().setHours(0,0,0,0)) || (current > this.state.deadline_limit);

		else return current && current < new Date().setHours(0,0,0,0);
	}	  

	formatTreedata = (data) => {
		let template = (f, loc) => {
			return {
				"title": <div className="list-group-item">{f.link && f.link.includes("presentation") ? <FilePptOutlined />: <FileOutlined />}
					 <Button type="link" onClick={(e) => window.open(f.link,'_blank')}>{f.label}</Button> <Button size="small" onClick={() => this.addSubTask(f.label, null, loc)}>Add as a sub-task</Button></div>,
				"key": f.value,
				"link": f.link
			}
		};
		// helper for action items; set up related team documents
		this.state.treeData = data.map(function(f) {
			let t = template(f, [{"category": "", value: [f.value]}]);
			if(f.children) {
				t["children"] = f.children.map(c => {
					let tc = template(c, [{"category": "", value: [f.value, c.value]}]);
					if(c.children)
						tc["children"] = c.children.map(cc => template(cc, [{"category": "", value: [f.value, c.value, cc.value]}])); 
					return tc;
				});
			}
			return t;
		})	
	}

	getLocation(locationValue) {
		if(this.isURL(locationValue))
			return {"link": locationValue, "label": locationValue};

		const findNode = (id, options) => {
			if(!options?.length) return null; 

			for (var i = 0; i < options.length; i += 1) {
				if(id === options[i].value)
					return options[i];
			}
		}

		// debugger;
		let cp_options = [...this.state.file_options]
		locationValue.map((value, i) => {
			cp_options = findNode(value, i === 0 ? cp_options : cp_options.children) || cp_options;
			return null;
		})
		return cp_options;
	}
	
	getPerformersNames() {
		return this.state.performers.map(p => this.props.userMap[p].name).join(', ').replace(/,(?=[^,]*$)/, ' and');
	}

	async getOtherTasks() {
		let tasks_stakeholders = [];
		
		if(!this.state.otherTasks.length) {
			const t = await getTaskList(this.props.userID);
			return t;
		}
		
		return this.state.otherTasks;
	}

	async getSimilarDeadlineTask(deadline) {
		let tasks_stakeholders = await this.getOtherTasks();
		const otherTasks = tasks_stakeholders.filter(t => t.id !== this.props.taskID);
		const this_month = new Date().getMonth()+1 < 10 ? "0" + (new Date().getMonth()+1) : new Date().getDate()+1;
		const today_date = new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate();
		tasks_stakeholders = tasks_stakeholders.filter(t => t.id !== this.props.taskID && t.data().deadline && t.data().deadline >= `${this_month}-${today_date}`);


		tasks_stakeholders.sort((a, b) => a.deadline - b.deadline)
		let t = tasks_stakeholders.slice(0, 5).map(t => {
			let reqs = t.data().requesters.map((id, i) => i === 0 ? (id === this.props.userID ? 'Me' : this.props.userMap[id].name) : ", " + (id === this.props.userID ? 'Me' : this.props.userMap[id].name));
			return {
				title: <div className="list-group-item sub-task">{t.data().title || "[Untitled]"} <i>from {reqs}</i></div>, 
				deadline: convertToFriendlyDate(t.data().deadline)
			}
		});
		
		let final = t.map(task => <Col span={24}><div style={{float: "left"}}>{task.title}</div> <span style={{float: "left", padding: "10px"}}>{task.deadline}</span></Col>); 
		this.setState({similarDeadlineTasks: final, otherTasks: otherTasks});
	}

	async getSimilarTimedTask(anticipated_time, performers) {
		let tasks_stakeholders = await this.getOtherTasks();
		const otherTasks = tasks_stakeholders.filter(t => t.id !== this.props.taskID);
		tasks_stakeholders = tasks_stakeholders.filter(t => t.id !== this.props.taskID && t.data().anticipated_time);
		
		tasks_stakeholders.sort((a, b) => Math.abs(parseInt(anticipated_time)- parseInt(a.data().anticipated_time)) - Math.abs(parseInt(anticipated_time)-parseInt(b.data().anticipated_time)))
		let t = tasks_stakeholders.slice(0, 5).map(t => {
			let reqs = t.data().requesters.map((id, i) => i === 0 ? (id === this.props.userID ? 'Me' : this.props.userMap[id].name) : ", " + (id === this.props.userID ? 'Me' : this.props.userMap[id].name));
			return {
				title: <div className="list-group-item sub-task">{t.data().title || "[Untitled]"} <i>from {reqs}</i></div>, 
				anticipated_time: t.data().anticipated_time
			}
		});
		
		let final = t.map(task => <Col span={24}><div style={{float: "left"}}>{task.title}</div> <span style={{float: "left", padding: "10px"}}>{task.anticipated_time} mins</span></Col>); 
		this.setState({similarTasks: final, otherTasks: otherTasks});
	}

	isURL(str) {
		var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
		  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
		  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
		  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
		  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
		  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
		return !!pattern.test(str);
	  }

	async loadDocStructure(documentID, documentIndex, access_token, isCurrentLocation=false) {
		let self = this;
		// load from google api
		await fetch(`https://docs.googleapis.com/v1/documents/${documentID}?access_token=${access_token}`)
		.then(response => response.json())
		.then(response => {
			console.log(response);
			var content = response.body.content;

			var t = [];

			content.map(c => {
				if(c.paragraph && c.paragraph.paragraphStyle && c.paragraph.paragraphStyle.headingId) {
					let item = {
						"value": c.paragraph.paragraphStyle.headingId,
						"label": c.paragraph.elements[0].textRun.content,
						"link": `https://docs.google.com/document/d/${documentID}#heading=` + c.paragraph.paragraphStyle.headingId,
						"children": []
					};

					if(c.paragraph.paragraphStyle.namedStyleType === "TITLE" || t.length === 0) {
						t.push(item); 	
					}
					else {
						t[t.length - 1]["children"].push(item);
					}
				}
				return 0;
			})

			console.log(t);


			self.state.file_options[documentIndex]["children"] = t;
			self.formatTreedata(self.state.file_options);
		})
		.catch(error => {
            
            console.error('There was an error!', error);
        });
	}

	handleRequestersChange(value) {
		let s  = document.querySelector(".task-title-container .ant-select-selection-item-remove");
		if(value.length === 1) {
			// hide delete button X
			if(s) s.style.display = "none";
		}
		else {
			// show delete button
			if(s) s.style.display = "block";
		}
			
		this.setState({ requester_names: value });
		this.saveChanges(value.map(v => this.state.reverse_users_list[v]), "requesters");
	  }

	replacePerformerText() {
		// whenever the user updated the performer, replace the word performer to their name
	}

	saveChanges(new_text, field) {
		let extra = {}
		// auto-save changes to db
		if(this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			
			if(this.props.is_performer && field != "what") {
				// instead of change the value directly
				// create-suggestion
				this.setState({[field+"_change"]: true});
				this.create_new_suggestion(field, new_text);
			}

			else {
				if(field === "performers") {
					const old_performers = this.state.performers;
					const new_performers = new_text;
					
					// get added data -> add using set data
					const added_performers = new_performers.filter(x => !old_performers.includes(x));
					const new_data = {};

					added_performers.map(p => {
						return new_data["performers." + p] = {"priority": "", "status": p === this.props.userID ? "TODO":"WAITING_DRAFT", "workStatus": p === this.props.userID ? "TODO":"WAITING_DRAFT"};
					})
					
					// if this is requester's personal TODO, bypass to TODO status
					if(Object.keys(old_performers).length + added_performers.length ===1 && added_performers[0] === this.props.userID)
						extra["workStatus"] = "TODO";
					else //if(Object.keys(old_performers).length + added_performers.length > 1 && added_performers[0] === this.props.userID)
						extra["workStatus"] = "DRAFTING";

					setData(new_data, "tasks", this.props.taskID)

					// get removed data -> remove with delete field 	
					const removed_performers = old_performers.filter(x => !new_performers.includes(x)).map(x => "performers."+x);
					deleteField(removed_performers, "tasks", this.props.taskID); 
				} 
				// else if(field == "what") {
				// 	// detect delta 

				// 	let sub_tasks = new_text.map((e,i) => e.task ? i : undefined).filter(x => x);
				// 	Promise.all(sub_tasks.map(w => {createTask(this.props.userID, {"title": new_text[w].value, "why": this.props.taskID}); }))
				// 		.then(sub_tasks => {
				// 			let sub_task_ids = sub_tasks.map(s => s.id);

				// 			for (let i=0; i< sub_task_ids.length; i ++) {
				// 				new_text[sub_tasks[i]] = sub_task_ids[i]
				// 			}

				// 			setData({"what": new_text}, "tasks", this.props.taskID)
				// 		})
					
				// }
				else {
						var data = {};
						data[field] = new_text
						// debugger;
						setData(data, "tasks", this.props.taskID)
				}
				
			}

			// console.log(new_text);
			this.setState({...extra, [field]: new_text});
			
		}, 500);
	}

	

	savePriority(v) {
		if(this.props.is_performer) {
			var data = {};
			data[`performers.${this.props.userID}.priority`] = v;
			setData(data, "tasks", this.props.taskID);
		} else {
			// save it to main level for requesters
			setData({"priority": v}, "tasks", this.props.taskID);
		}
	}

	sendDraft() {
		let bothArray = this.props.requesters.filter(value => Object.keys(this.props.performers).includes(value));
		this.state.performers.map(p => { 
			if (bothArray.includes(p) || p === this.props.userID) { // when requester and performer are the same person
				return changeTaskStatus(this.props.taskID, p, "TODO");
			} else {
				return changeTaskStatus(this.props.taskID, p, "ACCEPT_OR_REJECT");
			}	
		})
		
		if (!this.props.is_performer) {
			if (this.props.requester.includes(this.props.performer) && this.props.requester!=='' && this.props.performer!=='') { // when requester and performer are the same person
				this.setState({
					"status": "TODO",
					"workStatus": "TODO",
					"performer": this.state.performer 
						|| this.props.userID // for the case that this task is newly created and there were no performer for the task
				});
			} else {
				this.setState({"status": "WAITING_RES", "workStatus": "WAITING_RES"});
			}
			
		}

		//send email to performers
		this.state.performers.map(r => {
			this.sendEmail(this.props.userMap[r]);
		})
	}

	sendEmail = (recipient) => {
		// send email whenever there is a change at task that counterpart has to be notified
		emailjs.send('service_o3aqz0r', 'template_g0diom5', {
		  'to_name': recipient.name,
		  'to_email': recipient.email.includes("karger") ? "karger@mit.edu": recipient.email,
		  'from_name': this.props.userMap[this.props.userID].name,
		  'team_name': getUrlParameter2("team"),
		  'message': this.state.title, // task title
		}, 'user_jzbkzTRjGnVizvlvvPsZG')
		  .then((result) => {
			console.log("update email sent");
			  // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
		  }, (error) => {
			  console.log(error.text);
		  });
	}

	acceptSuggestion(field) {
		this.saveChanges(this.state.suggestion[field], field); 
		// remove suggestion from db
		this.rejectSuggestion(field);
	}

	rejectSuggestion(field) {
		deleteField([field], "suggestion", this.props.taskID);
		
		let s = this.state.suggestion;
		delete s[field];
		this.setState({"suggestion": s, [field + "_change"]: false, [field + "_show"]: true});

		// if there is no suggestion left, change to workStatus
		if(Object.keys(this.state.suggestion).filter(s => s !== "suggested_by").length == 0) {
			changeTaskStatus(this.props.taskID, this.state.performer, this.state.workStatus); 
			this.setState({"status": this.state.workStatus}); 
		}
			

		//send email to performers
		this.state.requesters.map(r => {
			this.sendEmail(this.props.userMap[r]);
		})
	}

    acceptTask() {
        let performer = this.state.performers.filter(p => p === this.props.userID); 
        changeTaskStatus(this.props.taskID, performer, "ACCEPTED"); 
        this.setState({"status": "ACCEPTED", "workStatus": "ACCEPTED"}); 
    }

    rejectTask() {
        let performer = this.state.performers.filter(p => p === this.props.userID); 
        changeTaskStatus(this.props.taskID, performer, "REJECTED"); 
		this.setState({"status": "REJECTED", "workStatus": "REJECTED"}); 
		
		//send email to requesters
		this.state.requesters.map(r => {
			this.sendEmail(this.props.userMap[r]);
		})
    }

    snoozeTask() {
        let performer = this.state.performers.filter(p => p === this.props.userID); 
        changeTaskStatus(this.props.taskID, performer, "BACKLOGGED"); 
        this.setState({"status": "BACKLOGGED", "workStatus": "BACKLOGGED"});
        // TODO: gray out column
        // TODO: change to ACCEPT_OR_REJECT after some time
	}

	completeTask = () => {
		// when performer said they are done with job
		let performer = this.state.performers.filter(p => p === this.props.userID); 
        changeTaskStatus(this.props.taskID, performer, "COMPLETED"); 
		this.setState({"status": "COMPLETED", "workStatus": "COMPLETED"}); 
		
		//send email to requesters
		this.state.requesters.map(r => {
			this.sendEmail(this.props.userMap[r]);
		})
	}

	requesterCompleteTask = () => {
		changeTaskStatus(this.props.taskID, this.state.performer, "LOOKS_GOOD");
		this.setState({"status": "LOOKS_GOOD", "workStatus": "LOOKS_GOOD"});
	}

	reviewLooksGood = () => {
		changeTaskStatus(this.props.taskID, this.state.performer, "LOOKS_GOOD");
		this.setState({"status": "LOOKS_GOOD", "workStatus": "LOOKS_GOOD"});
	}

	sendBack = () => {
		changeTaskStatus(this.props.taskID, this.state.performer, "LOOKS_GOOD");
		changeTaskWorkStatus(this.props.taskID, this.state.performer, "REVISE");
		this.setState({"status": "SENT_BACK", "workStatus": "SENT_BACK"}); 
		this.renderSwitch("SENT_BACK"); 
	}
		
	askClarification = () => {
		if (this.state.is_performer) {
			let performer = this.state.performers.filter(p => p === this.props.userID); 
			changeTaskStatus(this.props.taskID, performer, "WAITING_CLARIFICATION"); 
			this.setState({"status": "WAITING_CLARIFICATION"});
			
			//send email to performers
			this.state.requesters.map(r => {
				this.sendEmail(this.props.userMap[r]);
			})
		} else { // requester
			let new_data = {}; 
			let performers = [...this.state.performers]; 

			if(this.props.performer)
				new_data[`performers.${this.props.performer}.status`] = "ANSWER";
			else {
				this.state.performers.forEach(p => new_data[`performers.${p}.status`] = "ANSWER");
			}

			setData(new_data, "tasks", this.props.taskID)
			this.setState({"status": "WAITING_CLARIFICATION"}); 
			
			//send email to performers
			this.state.performers.map(r => {
				this.sendEmail(this.props.userMap[r]);
			})
		}
	}

	askUpdate = () => { // only for requester
		let new_data = {}; 
		new_data[`performers.${this.props.performer}.status`] = "REQUESTED_UPDATE";
		setData(new_data, "tasks", this.props.taskID);
		
		this.setState({"status": "REQUEST_UPDATE"});

		//send email to the performer
		this.sendEmail(this.props.userMap[this.props.performer]);
	}

	sendRegularChat = () => {
		if (this.state.is_performer) {
			let performer = this.state.performers.filter(p => p === this.props.userID); 
			changeTaskStatus(this.props.taskID, performer, "SENT_CHAT"); 
			this.setState({"status": "SENT_CHAT"});
			
			if(this.props.taskLighter)
				this.state.requesters.map(r => {
					this.sendEmail(this.props.userMap[r]);
				})
		} else { // requester
			let new_data = {}; 
			let performers = [...this.state.performers]; 
			performers.map( async (p) => {	
				let perfPriority = ''; 
				let perfStatus = '';
				let perfWorkStatus = ''; 
				await getTask(this.props.taskID).then(res => {
                    perfPriority = res.data().performers[p].priority; 
					perfStatus = res.data().performers[p].status; 
                    perfWorkStatus = res.data().performers[p].workStatus; 
                }); 
				if (p === this.props.performer) {
					new_data["performers." + p] = {"priority": perfPriority, "status": "HAVE_CHAT", "workStatus": perfWorkStatus};
					changeTaskStatus(this.props.taskID, p, "HAVE_CHAT"); 
				} else {
					new_data["performers." + p] = {"priority": perfPriority, "status": perfStatus, "workStatus": perfWorkStatus};
				}
				this.setState({"status": "SENT_CHAT"}); 
				return new_data; 
			})
			setData(new_data, "tasks", this.props.taskID)

			if(this.props.taskLighter)
				this.sendEmail(this.props.userMap[this.props.performer]);
		}	
	}

    assignAsPrereq() {
        // console.log("prereq")
    }

  	// renderDropdown = (menus) => {
	// 	return (
	// 	  <div>
	// 		{menus}
	// 		<Divider style={{ margin: 0 }} />
	// 		<div style={{ padding: 8, display: this.state.location && this.state.location.length > 0 ? "none":"block" }}><Button onClick={this.create_new_document} type="dashed"><PlusSquareTwoTone /> Create a new document</Button></div>
	// 	  </div>
	// 	);
	//   }

	renderLocation = (values, selectedOptions) => {
		// triggered when users change the location of the task. This function displays hyperlinks with the updated location and save it to DB
		// this.setState({"location_link": values.length? selectedOptions[selectedOptions.length -1].link
		// 	: ""});

		// alert(this.state.location);

		// this.saveChanges([...this.state.location, {value: values, category: "CATEGORY"}], "location")
	}

	handleVisibleChange = (visible, field) => {
		if (visible) {
			this.setState({ visiblePopoverField: field });
		} else {
			this.setState({ visiblePopoverField: null });
		}
	};
	
	renderAddButton= (field, content=null, if_exist="") => {
		if(this.state[field + "_show"] || this.state[field + "_change"] || field in this.state.suggestion_request || (this.state[field] && this.state[field]?.length > 0 )) return if_exist; 

		return <Popover content={("Add " + (content || field))}><Button type="default" onClick={(e) => {this.setFieldVisible(field)}}>[{content || field}]</Button></Popover>
	}

	renderPopover = (field, label=null) => {
		let suggestion_display = (field) => {switch(field){
			case 'performers': return this.state.suggestion.performers?.map(p => this.props.userMap[p].name).join(', ');
			case 'anticipated_time': return this.state.suggestion.anticipated_time + " mins";
			case 'what': return this.state.suggestion.what?.map(w => <span><u>{"⚫ " + w.value } <ExportOutlined/></u> {w.task ? "(sub-task)":""}</span>);
			case 'location': return this.state.suggestion.location.map(l => (this.getLocation(l.value)["category"] || "Resource") + ": " + this.getLocation(l.value)['label']).join("\n");
			default: return this.state.suggestion[field]
			}
		}
		return <Popover visible={this.state.visiblePopoverField === field} 
			onVisibleChange={(visible) => this.handleVisibleChange(visible, field)}
			content={(this.state[field + "_change"] ? 
				<div>{this.props.userMap[this.state.suggestion.suggested_by[field]].name} suggested following:<br/> 
					{suggestion_display(field)}
						<br/>
					<Button type="primary" onClick={(e) => this.acceptSuggestion(field)}>Accept</Button><Button type="danger" onClick={(e) => this.rejectSuggestion(field)}>Reject</Button></div>: 
				(this.state.performers?.length ? <Button type="link" onClick={(e) => this.create_new_suggestion(field)}>Request suggestion to {this.getPerformersNames()}</Button>
				:<span>Assign performers first to get their suggestion on this field</span>))
			} > 
				<Button type='default'>{label || field}</Button>
			</Popover>
	}

	// Give warning to requesters that they request suggestion to performers
	renderRequestWarning = (field) => {
		return <Tag ref={_ => {this[field] = _;}} closable onClose={() => {
				let s=this.state.suggestion_request;
				delete s[field];
				this.rejectSuggestion(field);
				this.setState({"suggestion_request": s})
			}}>
			You requested suggestion to {this.getPerformersNames()}</Tag>
	}

	renderHelpMsg = (field) => {
		return	this.props.is_performer ? 
			(this.state[field + "_change"] ? "Your suggestion will be reviewed by " + this.state.requester_names : (field in this.state.suggestion_request ?  "Requesters have asked you for a suggestion" : ""))// for performer
			: (this.state[field + "_change"] ? this.props.userMap[this.state.suggestion.suggested_by[field]].name + " suggested a change" : (field in this.state.suggestion_request ?  `You asked ${this.getPerformersNames()} for their suggestion` : ""))// for requester
	}

	renderInputField = (field) => {
		let defaultVal = this.props.is_performer && this.state.suggestion[field] ? this.state.suggestion[field] : this.state[field];

		if(field == "performers")
		 	return <Select
				mode="multiple"
				allowClear
				ref={_ => {this.performers = _;}}
				style={{ width: '100%' }}
				placeholder="Please select people who will perform this task"
				labelInValue
				name="performers"
				key={Math.random()}
				defaultValue={(defaultVal || []).map((id) => ({"value":id}))}
				value={(defaultVal || []).map((id) => ({"value":id}))}
				onChange={(e)=>  {this.saveChanges(e.map((p) => p.value), "performers") }}
				filterOption={(input, option) =>
                        // handling option.children.toLowerCase() error  
                        option.children ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0:false
                     }
				 >
				{/* onChange={(e)=>  {this.saveChanges(e.map((p) => p.value), "performers"); if(!this.props.is_performer) {this.setState({status: 'DRAFTING', workStatus: 'DRAFTING'});} }} > */}
				{Object.keys(this.props.userMap).filter(id => !this.state.requesters.includes(id)).map((id) => <Option key={id}>{this.state.userMap[id].name}</Option>)}
			</Select>;
		else {
			if(this.props.taskLighter) return "";
			else {
				if(field == "deadline")
				return <DatePicker ref={_ => {this.deadline = _;}} onChange={(m, date_str)=>  this.saveChanges(date_str, "deadline")} defaultValue={defaultVal && moment(defaultVal, this.dateFormat)} disabledDate={this.disabledDate} style={{float: 'left', width: '35%'}} key={Math.random()}/>;
				else if(field == "location_search")
					return <div>
						<Cascader ref={_ => {this.location = _;}} style={{float:"left", width: "80%"}} notFoundContent={<div style={{display:"none"}}></div>} /* dropdownRender={this.renderDropdown}*/ placeholder="Find a team document" onChange={(v, s)=> this.setState({"location_search":v})} showSearch={{filter: this.filter}} value={defaultVal} options={this.state.file_options} changeOnSelect />
						{/* Weird bug, if there is only one cascader, the initial value is not loaded so I need to init one more to make it work */}<Cascader style={{ display: 'none' }} options={[]} changeOnSelect />
						<Button disabled={!this.state.location_search.length} onClick={(e) => {this.saveChanges([...this.state.location, {value: this.state.location_search, category: ""}], "location");this.setState({"location_search": []})}} type="default" style={{float: "left"}}>Add</Button>
						<a href={`https://drive.google.com/drive/u/0/folders/${Object.entries(this.props.userMap).filter(([k, v]) => v.folderID)[0][1].folderID}`} target="_blank" rel="noreferrer"><Tooltip title="Open team folder"><FolderOpenOutlined style={{float: "left", "fontSize": "xx-large", "marginLeft": "5px"}}/></Tooltip></a>
						</div>;
				else if(field == "anticipated_time")
					return <Input ref={_ => {this.anticipated_time = _;}} id="anticipated_time" onChange={(e)=> {
						if(e.target.value <=0) e.target.value = e.target.value * (-1);
						this.saveChanges(e.target.value, e.target.id); 
						this.getSimilarTimedTask(e.target.value, this.state.performers)}} 
						defaultValue={defaultVal} value={defaultVal} suffix="mins" placeholder="30" type="number" min="0" style={{ float: 'left', width: '120px' }}/>;
			}
		}
		

		return <div>not match</div>;
	}

	renderWhat = (new_what) => {
		if(this.props.is_performer) {
			// tell them the suggestion will be viewed by requesters

		}
		
		this.saveChanges(new_what, "what")
	}

	// set a field visible per user's request
	setFieldVisible = (field) => {
		let new_state = {[field + "_show"]: true};
		this.setState(new_state, () => field in this && this[field].focus());
	}

	// set a field visible per user's request
	setFieldEditable = (field) => {
		let new_state = {[field + "_edit"]: true};
		this.setState(new_state, () => field in this && this[field].focus());
	}

	renderPerformerPopover = (field) => {
		let field_not_exist = !this.props[field];
		if(field === "location") {
			field_not_exist = this.state.location.length == 0;
			console.log('do something different about requesting info');
		}

		// {field_not_exist && <button onClick={(e) => this.create_new_suggestion(field)}>Request for info</button>}
		return <span><button onClick={(e) => this.setFieldEditable(field)}>Make a suggestion</button> {!field_not_exist && <button onClick={(e) => {insertQuote(field, this.state[field]); window.scrollTo({
			top: document.body.scrollHeight,
		behavior: 'smooth'});}}>Quote</button>}</span>
	}

	renderSwitch = (param) => {
		// param is workStatus
		switch(param) {
			case 'DRAFTING': 
				return (
                    <div style={{textAlign: 'center'}}>
						{this.state.performers.filter((p) => p in this.state.performers ? this.state.performers[p].status === "WAITING_DRAFT" : true).length ? 
							<Button onClick={() => this.sendDraft()}>Send to {this.state.performers.filter((p) => 
								p != this.props.userID && (p in this.state.performers ? this.props.performers[p].status === "WAITING_DRAFT" : true))
								.map(p => this.props.userMap[p].name).join(", ").replace(/,(?=[^,]*$)/, ' and')}</Button>
							: <Button disabled>Assign performers to send</Button>}
                    </div>
                );
			case 'WAITING_DRAFT':
				return(
				<div style={{textAlign: 'center'}}>
						{
							this.state.requester !== '' && this.state.status !== 'WAITING_DRAFT' ?
							`${this.state.requester.map(r => this.props.userMap[r].name)} is preparing to send this task.`
							:
							''
						}
				</div>
				);
			case 'WAITING_RES':
				return (
					<div style={{textAlign: 'center'}}>
						{
							this.state.performer !== '' && this.state.status !== 'WAITING_RES' ?
							`You have sent your response and are waiting for a response from ${this.props.userMap[this.state.performer].name}.`
							:
							''
						}
					</div>
				);
			case 'ACCEPT_OR_REJECT':
				return (
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={() => this.acceptTask()}>✅ Accept</Button>
                        {/* <Select defaultValue="🤷 Maybe" onChange={(v) => v === "snooze" ? this.snoozeTask() : this.assignAsPrereq()} style={{width: 240}}>
                            <Option value="snooze">Remind me later</Option>
                            <Option value="prereq">Create as a pre-requisite task</Option>
                        </Select> */}
						<Popover content={(<div>Please leave a suggestion<br/> for who can replace you<br/><Button onClick={(_) => {this.setFieldVisible("performers")}}>Suggest performers</Button></div>)}>
                        	<Button onClick={() => this.rejectTask()}>❌ Can't do</Button>
						</Popover>
                    </div>
                ); 
			case 'BACKLOGGED': 
					return <div></div>
			case 'ACCEPTED': 
			case 'SENT_CHAT': 
				return (
					this.props.is_performer ? (
					<div style={{textAlign: 'center'}}>
						<Button onClick={() => this.completeTask()}>🎉 Mark as Complete</Button>
						<Popover content={(<div>Please leave a suggestion<br/> for who can replace you<br/><Button onClick={() => this.setFieldVisible("performers")}>Suggest performers</Button></div>)}>
                        	<Button onClick={() => this.rejectTask()}>❌ Can't do anymore</Button>
						</Popover>
					</div>) 
					: ( // requester can mark task as complete 
					<div style={{textAlign: 'center'}}>
						<Button onClick={() => this.requesterCompleteTask()}>🎉 Mark as Complete</Button>
						<Popover content={(<div>Request work progress<br/><Button onClick={(_) => {this.askUpdate()}}>Bump this for {this.props.userMap[this.props.performer].name}</Button></div>)}>
							<Button>❓ What's up?</Button>
						</Popover>
					</div>)
				)
			case 'TODO': 
				return (
					<div style={{textAlign: 'center'}}>
						<Button onClick={() => this.requesterCompleteTask()}>🎉 Mark as Complete</Button>
					</div>
				)		
			case 'REQUESTED_UPDATE': // performer
				return (
					<div style={{textAlign: 'center'}}>
						<Popover content={(<div>Add some public action items or make some of your private tasks to public so requesters know what are your blockers</div>)}>
							<Button>Share update</Button>
						</Popover>
						<br/><br/>
						<Button onClick={() => this.completeTask()}>🎉 Mark as Complete</Button>
						<Popover content={(<div>Please leave a suggestion<br/> for who can replace you<br/><Button onClick={() => this.setFieldVisible("performers")}>Suggest performers</Button></div>)}>
							<Button onClick={() => this.rejectTask()}>❌ Can't do anymore</Button>
						</Popover>
					</div>)
			case 'REQUEST_UPDATE': // requester
				return (
					<div style={{textAlign: 'center'}}>
						<Button onClick={() => this.requesterCompleteTask()}>🎉 Mark as Complete</Button>
					</div>
				)	
			case 'REVIEW': 
				return (
					<div style={{textAlign: 'center'}}>
						<Button onClick={() => this.reviewLooksGood()}>🤩 Looks Good!</Button>
                        <Button onClick={() => this.sendBack()}>😕 Send Back</Button>
					</div>
				)
			case 'REVISE': 
				return (
					this.props.is_performer && (
					<div style={{textAlign: 'center'}}>
						<Button onClick={() => this.completeTask()}>🎉 Mark as Complete</Button>
						<Popover content={(<div>Please leave a suggestion<br/> for who can replace you<br/><Button onClick={() => this.setFieldVisible("performers")}>Suggest performers</Button></div>)}>
							<Button onClick={() => this.rejectTask()}>❌ Can't do anymore</Button>
						</Popover>
					</div>)
				)
			case 'SENT_BACK': 
				return (
					<div style={{textAlign: 'center'}}>
						<Alert message="Please leave a chat message for what should be done to complete the task." type="warning" showIcon />
					</div>
				)
			case 'ANSWER':
				return (
					<div style={{textAlign: 'center'}}>
						{Object.keys(this.state.suggestion).length} suggestions to review 
					</div>
			);
      default: 
        return <div></div>;  
		}
	}

	suggestChange(field, value) {

	}

	filter = (inputValue, path) => {
		
		if(this.location_timeout) clearTimeout(this.location_timeout);
		this.location_timeout = setTimeout(() => { // set timer so the db save doesn't get trigger too frequent
			// put the new document in a cascader option
			let new_option = [...this.state.file_options];
			new_option.splice(-1,1); // remove last item
			new_option.push({"value": inputValue, "label": inputValue});
			this.setState({"file_options": new_option})
		}, 500);

	   return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
	 }


	onSubmit = (event) => {
		event.preventDefault();
	
		var newItemValue = this.refs.itemName.value;
		
		if(newItemValue) {
			this.addSubTask(newItemValue, this.state.publicItems ? this.state.publicItems.length - 1:0);
			this.formRef.reset();
		}
	}

	render() {
		const filteredOptions = Object.keys(this.props.userMap).map((id) => this.props.userMap[id].name).filter(o => !this.state.requester_names.includes(o));
		const subtaskNarrativeGuide = this.state.what?.filter(w => (this.props.performer) ? [...(Object.keys(w.performers || {})), ...(w.requesters || []), ...(w.stakeholders || [])].indexOf(this.props.performer) > -1 : (this.state.performers?.length ? false: true)) || [];

		// stakeholders view
		if(!this.props.taskLighter && !this.props.is_performer && !this.state.requesters.includes(this.props.userID))
			return <div id="view-interface">
			<div id="leftcolumn"></div>
				<div id="contentliquid" style={{height: "1000px"}}>
					<div id="content">
						<div className="task-title-container"> 
							<Input id="title" ref={_ => {this.title = _;}} onKeyUp={(e)=>  this.saveChanges(e.target.value, e.target.id)} placeholder="Request title e.g., sign a document" defaultValue={this.props.title} style={{float: "left", width: "71%"}} type="text" bordered={false}/>
							<span style={{float: "right", width: "55px", paddingTop: '5px'}}>by {this.state.requester_names}</span>
						</div><br/>

						{/* other field display*/}
						<Form
							name="basic"
							style={{marginLeft: "30px"}}
							initialValues={{
								remember: true,
						}}>
							{["performers", "deadline", "anticipated_time", "why"].map(f => 
								<Form.Item
									label={f}
									name={f}
								>	
									{f === "performers" && <span>{this.getPerformersNames() || "Not specified"}</span>
									|| f === "deadline" && <span>{convertToFriendlyDate(this.props.deadline) || "No date specified"}</span>
									|| f === "anticipated_time" && <span>{this.props[f] ? (this.props[f] + "mins") : "Not specified"}</span>
									|| f === "why" && <span>{"value" in this.props[f] ? this.props[f].value : "Not specified"}</span>
									|| <span>{this.props[f] || "Not specified"}</span>}
								</Form.Item>
							)}

							<div className="action-items-requester">
								{/*<div onMouseEnter={() => {if(this.state.visibleHelperField !== "what") this.setState({visibleHelperField: "what"})}}>*/}
									<label className="popup">Decompose into smaller tasks that your performer can perform step-by-step:</label>
									<br/>
									<ActionItems draggable={false} items={this.state.actionItems} deleteSubTask={this.deleteSubTask} moveSubtask={this.moveSubtask} userID={this.props.userID} files={this.props.files} userMap={this.props.userMap}/>
									{this.state.actionItems.length && 
										(
											(this.props.is_performer && (this.state.actionItems[0].children.length + this.state.actionItems[1].children.length > 0))
											||
											(!this.props.is_performer && (this.state.actionItems[1].children.length > 0)))	?
										""
										: <span style={{marginLeft: "70px"}}>No subtasks for this task</span>
									}
							</div>
						</Form>
						
					</div>
				</div><div id="rightcolumn"></div>
			</div>;

		return <div id="view-interface">
            <div id="leftcolumn"></div>
			<div id="contentliquid">
				<div id="content">
					<div className="task-title-container"> 
						<Input id="title" ref={_ => {this.title = _;}} onKeyUp={(e)=>  this.saveChanges(e.target.value, e.target.id)} placeholder="Request title e.g., sign a document" defaultValue={this.props.title} style={{float: "left", width: "71%"}} type="text" bordered={false}/>
						<Select 
						// <Input ref={_ => {this.title = _;}} id="title" onKeyUp={(e)=>  this.saveChanges(e.target.value, e.target.id)} placeholder="Request title e.g., sign a document" defaultValue={this.props.title} style={{float: "left", width: "71%"}} type="text" bordered={false}/>
						// {/* <span style={{float: "left", width: "15px;"}}>by </span> */}
						// {/* <Select ref={_ => {this.requesters = _;}} */}
							ref={_ => {this.requesters = _;}}
							style={{float: "right"}}
							mode="multiple"
							placeholder="select requesters of this task"
							value={this.state.requester_names}
							onChange={(v) => this.handleRequestersChange(v)}
							optionLabelProp="label" bordered={false}
						>
						{filteredOptions.map(item => (
							<Select.Option key={item} value={item}>
								{item}
							</Select.Option>
							))}
						</Select>
                        <span style={{float: "right", width: "15px", paddingTop: '5px'}}>by </span>
					</div><br/>
					<div id="status-container" className="drag-hide" style={{marginLeft: "30px", marginBottom: "55px"}}>
						<Row>
							<Col span={13}>
								<b><u>Status:</u> {STATUS[this.state.status]}</b><br/>
								{this.props.performer && this.props.requester && this.props.performer !== '' && this.props.requester !== '' && (
									<p style={{float: "left"}}>{this.state.status in statusPrompt ? statusPrompt[this.state.status]( this.props.is_performer ? null : this.props.userMap[this.props.performer].name, this.props.is_performer ? this.props.requester.map(r => this.props.userMap[r].name).join(', ').replace(/,(?=[^,]*$)/, ' and') : null) : ''}</p>
								)}
								{this.props.performer === '' && this.props.requester === '' && (
									<p style={{float: "left"}}>{this.state.status in statusPrompt ? statusPrompt[this.state.status]( this.props.is_performer ? null : this.state.performers.map(p => this.props.userMap[p].name).join(', ').replace(/,(?=[^,]*$)/, ' and'), this.props.is_performer ? this.state.requester_names.join(', ').replace(/,(?=[^,]*$)/, ' and') : null) : ''}</p>
								)}
							</Col>
							<Col span={11}>
								<br/><br/>
								{this.renderSwitch(this.state.workStatus)}
							</Col>
						</Row>					
					</div>

          			<Form
					name="basic"
					style={{marginLeft: "30px"}}
					initialValues={{
						remember: true,
					}}>
					<Form.Item
						className="drag-hide"
						label={this.props.is_performer || this.props.taskLighter? "Performers" : 
							this.renderPopover("performers")}
						name="performers"
						hasFeedback={this.state.performers_change}
						validateStatus="success"
						help={this.state.performers_change ? (this.props.is_performer? "Your suggestion will be reviewed by " + this.state.requester_names : this.props.userMap[this.state.suggestion.suggested_by['performers']].name + " suggested a change"): ""}
					>
						{this.props.is_performer ? 
							(this.state.suggestion.performers || this.state.performers_show ?// performer interface
								this.renderInputField("performers")
								:
								<span className="popup">
									{this.getPerformersNames()} 
									<span><button onClick={(e) => this.setFieldVisible("performers")}>Suggest change</button> <button>Quote</button></span>
								</span>
							)
							: ( "performers" in this.state.suggestion_request ?  // requester interface
								this.renderRequestWarning("performers") // feedback to requester when they ask for suggestion:
								: this.renderInputField("performers")
							)
						}
					</Form.Item>

					<Form.Item
						onMouseEnter={() => {if(this.state.visibleHelperField !== "deadline") this.setState({visibleHelperField: "deadline"})}}
						label={this.props.is_performer? "Deadline" : this.renderPopover("deadline")}
						name="deadline"
						style={{display: this.props.deadline || this.state.deadline_show || this.state.deadline_change || "deadline" in this.state.suggestion_request ? "flex":"none", background: this.state.visibleHelperField === "deadline" && !this.props.is_performer?"#ECEEF6":""}}
						hasFeedback={this.state.deadline_change || ("deadline" in this.state.suggestion_request && this.props.is_performer)}
						validateStatus={"deadline" in this.state.suggestion_request && this.props.is_performer && !this.state.suggestion["deadline"] ? "warning" : "success"}
						help={this.renderHelpMsg("deadline")}
					>
						{this.props.is_performer ? 
							( (this.state.suggestion.deadline || this.state.deadline_edit) ?// performer interface
							this.renderInputField("deadline")
							:
							<span className="popup">
								{convertToFriendlyDate(this.props.deadline) || "No date specified"} 
								{this.renderPerformerPopover("deadline")}
							</span>
						)
							: ( "deadline" in this.state.suggestion_request?  // requester interface
								this.renderRequestWarning("deadline") // feedback to requester when they ask for suggestion:
								: this.renderInputField("deadline")
							)
						}
					</Form.Item>

					<Form.Item
						label={this.props.is_performer? "Resources" : this.renderPopover("location", "resources")}
						name="location"
						style={{display: (this.props.location  && this.props.location.length) || this.state.location_show || this.state.location_change || "location" in this.state.suggestion_request ? "flex":"none"}}
						hasFeedback={this.state.location_change || ("location" in this.state.suggestion_request && this.props.is_performer)}
						validateStatus={"location" in this.state.suggestion_request && this.props.is_performer &&  !this.state.suggestion["location"] ? "warning" : "success"}
						help={this.renderHelpMsg("location")}
					>
						{this.props.is_performer ? 
							( (this.state.suggestion.location || this.state.location_edit) ?// performer interface
								this.renderInputField("location_search")
								:
								<span className="popup">
								{this.props.location ? <a href={this.getLocation(this.props.location)['link']} target="_blank" rel="noreferrer">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a> : "No location specified"} 
								{this.renderPerformerPopover("location")}
							</span>
							)
							: ( "location" in this.state.suggestion_request?  // requester interface
								this.renderRequestWarning("location") // feedback to requester when they ask for suggestion:
								: this.renderInputField("location_search")
							)
						}
						{/* list of location */}
						<br/>
						<div style={{marginTop:"20px"}}>	
						{this.state.location?.length ? this.state.location?.map((l, index) =>
							<div>
								{(this.props.is_performer && (this.state.suggestion.location || this.state.location_show)) 
								|| (!this.props.is_performer && !("location" in this.state.suggestion_request)) ? 
								<span>
								<span onClick={(e) => this.saveChanges(this.state.location.filter(lo => JSON.stringify(lo.value) !== JSON.stringify(l.value)), "location")}>
									<MinusCircleTwoTone role="button" className="clickable anticon-mr" style={{cursor:"pointer"}} /> &nbsp;
								</span>
								<Select
									style={{ width: 240 }}
									placeholder="Select type of this resource"
									onChange={(e)=>  {
										const new_locations = this.state.location.filter(lo => JSON.stringify(lo.value) !== JSON.stringify(l.value));
										this.saveChanges([...new_locations, {category:e, value: l.value}], "location") }}
									value={l.category ? l.category: null}
									dropdownRender={menu => (
									<div>
										{menu}
										<Divider style={{ margin: '4px 0' }} />
										<div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
										<Input style={{ flex: 'auto' }} value={this.state.location_category} onChange={(e) => this.setState({location_category: e.target.value})} />
										<a
											style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
											onClick={() => this.setState({location_categories: [...this.state.location_categories, this.state.location_category || `New item`], location_category: ''})}
										>
											+ Add item
										</a>
										</div>
									</div>
									)}
								>
									{this.state.location_categories.map(item => (
									<Option key={item}>{item}</Option>
									))}
								</Select></span>
									: "* " + (l.category || "Resource") + ": "
								}
								
								
								<span style={{cursor:"pointer"}}>{(() => {
									if(!this.state.file_options || !l.value[0]) return "";
									const a= this.state.file_options.filter(f => f.value === l.value[0])[0];

									// custom text or link
									if(!a) 
										return this.isURL(l.value) ? <a href={l.value} target="_blank" rel="noreferrer">{l.value}</a>: l.value;

									if(l.value.length > 1 && a.children){
										const heading = a.children.filter(c => c.value === l.value[1])[0];
										return <a href={heading.link} target="_blank" rel="noreferrer">{a.label+ " > " + heading.label}</a>;
									}
										
									return <a href={a.link || a.value} target="_blank" rel="noreferrer">{a.label}</a>;
								})()}</span>
							</div>):
							"No Resource added"}
							
						</div>
					</Form.Item>

					<Form.Item
						onMouseEnter={() => {if(this.state.visibleHelperField !== "anticipated_time") this.setState({visibleHelperField: "anticipated_time"})}}
						label={this.props.is_performer? "How long would this take for performers?" : this.renderPopover("anticipated_time", "How long would this take for performers?")}
						name="anticipated_time"
						style={{display: this.props.anticipated_time || this.state.anticipated_time_show || this.state.anticipated_time_change || "anticipated_time" in this.state.suggestion_request ? "flex":"none", background: this.state.visibleHelperField === "anticipated_time" && !this.props.is_performer?"#FCF4F0":""}}
						hasFeedback={this.state.anticipated_time_change || ("anticipated_time" in this.state.suggestion_request && this.props.is_performer)}
						validateStatus={"anticipated_time" in this.state.suggestion_request && this.props.is_performer &&  !this.state.suggestion["anticipated_time"] ? "warning" : "success"}
						help={this.renderHelpMsg("anticipated_time")}
					>
						{this.props.is_performer ? 
							( (this.state.suggestion.anticipated_time || this.state.anticipated_time_edit) ?// performer interface
								this.renderInputField("anticipated_time")
								:
								<span className="popup">
									{this.props.anticipated_time ? (this.props.anticipated_time + " minutes") : "Unknown"}  
									{this.renderPerformerPopover("anticipated_time")}
								</span>
							)
							: ( "anticipated_time" in this.state.suggestion_request?  // requester interface
								this.renderRequestWarning("anticipated_time") // feedback to requester when they ask for suggestion:
								: this.renderInputField("anticipated_time")
							)
						}	
                    </Form.Item>
						{/* <Input ref={_ => {this.anticipated_time = _;}} id="anticipated_time" onKeyUp={(e)=>  this.saveChanges(e.target.value, e.target.id)} defaultValue={this.props.anticipated_time} suffix="mins" placeholder="30" type="text" style={{ width: 100 }}/>
					</Form.Item> */}

				</Form>

				<Form.Item
					label="Why does it need to be done?"
					name="why"
					style={{display: this.props.why || this.state.why_show ? "flex":"none", marginLeft: "30px"}}
				>
					{this.state.why && this.state.why["task-id"] ? <TodoListItem is_performer={this.props.is_performer} userMap={this.props.userMap} hide={true} userID={this.props.userID} item={this.state.why}  changeName={console.log} removeItem={console.log} markTodoDone={console.log} files={this.props.files}/>
						: (
							this.props.is_performer ?
							<span style={{marginLeft: "10px"}}>{this.props.why? this.props.why.value : ""}</span>
							:
							<TextArea ref={_ => {this.why = _;}} rows={4} id="why" onKeyUp={(e)=>  this.saveChanges({"value": e.target.value}, "why")} defaultValue={this.props.why? this.props.why.value: ""} placeholder="Why this task has to be done by deadline or blocking-task -- this information would help the performer to determine a priority of this task" type="text"/>
						)
					}
					
		
				</Form.Item>

				{this.props.taskLighter ? "":
				<div className={this.props.is_performer ? "":"action-items-requester"}
					style={{display: this.state.what_show || this.props.is_performer || (this.state.actionItems?.length && 
					this.state.actionItems[0].children.length + this.state.actionItems[1].children.length > 0 ) || this.state.what_change ? "block": "none", background: this.state.visibleHelperField === "what" && !this.props.is_performer?"#E8F6F9":""}}
					onMouseEnter={() => {if(this.state.visibleHelperField !== "what") this.setState({visibleHelperField: "what"})}}>
					{/*<div onMouseEnter={() => {if(this.state.visibleHelperField !== "what") this.setState({visibleHelperField: "what"})}}>*/}
						<label style={{marginLeft: "30px"}} className="popup">Decompose into smaller tasks that your performer can perform step-by-step:</label>
						<br/>
						<form ref={(el) => this.formRef = el} onSubmit={this.onSubmit}>
							<input ref={_ => {this.actionItems = _;}} type="text" style={{"border": 0,marginLeft: "40px", float: "left", width: "85%", border: "1px solid #ccc"}} ref="itemName" className="form-control" placeholder="add a new sub-task..."/>
							<Button onClick={this.onSubmit} type="default" style={{float: 'right'}}>Add</Button> 
						</form>
						<br/><br/><br/>
						<ActionItems draggable={true} items={this.state.actionItems} deleteSubTask={this.deleteSubTask} moveSubtask={this.moveSubtask} userID={this.props.userID} files={this.props.files} userMap={this.props.userMap} />
						{this.state.actionItems.length && 
							(
								(this.props.is_performer && (this.state.actionItems[0].children.length + this.state.actionItems[1].children.length > 0))
								||
								(!this.props.is_performer && (this.state.actionItems[1].children.length > 0)))	?
							""
							: <span style={{marginLeft: "70px"}}>No subtasks for this task</span>
						}
					</div>}

				

				<div style={{display: this.props.is_performer && !this.props.taskLighter ?"block": "none"}}>
					<Divider>Need more information?</Divider>
					{(!(this.state.deadline_show || this.state.deadline || this.state.deadline_change ||  "deadline" in this.state.suggestion_request) ||
					!(this.state.anticipated_time_show || this.state.anticipated_time || this.state.anticipated_time_change || "anticipated_time" in this.state.suggestion_request) ||
					!(this.state.location_show || this.state.location || this.state.location_change || "location" in this.state.suggestion_request)) ?
						<div>
						{this.renderAddButton("deadline")}
						{this.renderAddButton("anticipated_time", "How long this would take my time?")}
						{this.renderAddButton("location", "location of work")}</div>
						 : <span>There are no fields left to be added for this request. Leave suggestions or send chat messages to request more information.</span>		
					}
					
					{/* this.renderAddButton("custom", "custom field")*/}

					{/* <Form.Item>
						<Select
							showSearch
							style={{ width: 400 }}
							placeholder="Add fields from existing tasks"
							optionFilterProp="children"
							filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							filterSort={(optionA, optionB) =>
							optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							onChange={(val) => this.getTemplateFields(val)}
						>
							{
								memberTaskList.map((task, i) => 
									<Option value={'' + task.id + ''}>{task.data().title}</Option>
								)
							}
						</Select>
					</Form.Item> */}

				</div>
				
				{/* performers' template */}
				<Divider orientation="center">💬 Chat</Divider>
				<ChatWrapper status={this.state.status} is_performer={this.props.is_performer} sendRegularChat={this.sendRegularChat} askClarification={this.askClarification} userID={this.props.userID} TEAM_MEMBERS={this.props.userMap} taskID={this.props.taskID} taskLighter={this.props.taskLighter}/>

				{this.props.is_performer? "":
					<Tooltip title={this.state.deleteDisabled || this.state.what?.length ? "Cannot delete since people are still working on this" : "This action cannot be undone."}>
						<span><Button type="primary" danger disabled={this.state.deleteDisabled || this.state.what?.length} onClick={() => this.deleteTask()} style={{marginBottom: '20px'}}>
						Delete the task
						</Button></span>
					</Tooltip>
				}
				
		</div></div>
		<div id="rightcolumn">
			{/* <div id="status-container" className="drag-hide">
					<b><u>Status:</u> {STATUS[this.state.status]}</b>
					{this.props.performer && this.props.requester && this.props.performer !== '' && this.props.requester !== '' && (
						<p>{this.state.status in statusPrompt ? statusPrompt[this.state.status]( this.props.is_performer ? null : this.props.userMap[this.props.performer].name, this.props.is_performer ? this.props.requester.map(r => this.props.userMap[r].name).join(', ').replace(/,(?=[^,]*$)/, ' and') : null) : ''}</p>
					)}
					{this.props.performer === '' && this.props.requester === '' && (
						<p>{this.state.status in statusPrompt ? statusPrompt[this.state.status]( this.props.is_performer ? null : this.state.performers.map(p => this.props.userMap[p].name).join(', ').replace(/,(?=[^,]*$)/, ' and'), this.props.is_performer ? this.state.requester_names.join(', ').replace(/,(?=[^,]*$)/, ' and') : null) : ''}</p>
					)}
					{this.renderSwitch(this.state.workStatus)} */}
			
			{this.props.is_performer || this.props.taskLighter ? "": <div className="drag-hide">
				<Divider orientation="center"> Narrative Guide <Popover content={(<span>This text is to show how information you provide <br /> will help performers conduct tasks. The text <br /> itself won't be sent to performers</span>)}><QuestionCircleTwoTone /></Popover></Divider>
				<p className="narrative-preview" style={{"paddingLeft": "40px"}}>
					<span style={(this.props.performer || this.state.performers?.length)? {} : {opacity: "50%"}}>Hi <span className="link-to-input" onClick={(_) => this.performers.focus()}>{this.props.userMap[this.props.performer]? this.props.userMap[this.props.performer].name : this.getPerformersNames(this.state.performers) || "[Performer]"}</span>,</span><br/>
					<span type="title" style={this.state.title? {} : {opacity: "50%"}}>Can you help me <span className="link-to-input" onClick={(_) => this.title.focus()}>{this.state.title || "[Request title]"}</span> </span>
					<span type="deadline" style={this.state.deadline? {} : {opacity: "50%"}}>by&nbsp;<span className="link-to-input" onClick={(_) => this.deadline.focus()}>
						{this.renderAddButton("deadline", null, convertToFriendlyDate(this.state.deadline) || "[Deadline]")}</span>? </span>
					<span type="why" style={this.state.why && this.state.why.value ? {} : {opacity: "50%"}}>
						I need it to be done by then because <span className="link-to-input" onClick={(_) => this.why && this.why.focus()}>
						{this.state.why && this.state.why.value ? this.state.why.value: this.renderAddButton("why", "why you need this task to be done" || "[Why it has to be done]")}</span>. </span>
					<span type="anticipated_time" style={this.state.anticipated_time? {} : {opacity: "50%"}}>This task will approximately take  
						<span className="link-to-input" onClick={(_) => this.anticipated_time.focus()}> {this.renderAddButton("anticipated_time", "How long would this take?", this.state.anticipated_time ? this.state.anticipated_time + " mins" : "[How long will this take for performers?]")}</span> of your time. </span>
					<span type="location" style={this.state.location && this.state.location.length? {} : {opacity: "50%"}}>
						You can find <span className="link-to-input" onClick={(_) => this.location.focus()}>
							{this.state.location  && this.state.location.length ? <u>resources</u>
								: this.renderAddButton("location", "resources")}</span> of this task. 
						</span>
					<br/><br/>
					<span type="what" onClick={(_) => this.formRef.children[0].focus()} className="link-to-input" style={this.state.actionItems[1].children.length ? {} : {opacity: "50%"}}>To complete the task, please do the following: <br/>
						{ this.state.what_show  || this.state.actionItems[1].children.length ?
							// if there is no performer then show everything since the viewer is a requester. If the requester added performers it means currently they are not on particular performer's view. Hence don't show action items.
							(this.state.actionItems[1].children.length ? 
								this.state.actionItems[1].children.map((v, index) => 
									<span>{"⚫ " + (v["test"] || v["title"])}<br/></span> )
								: "[No action item for this performer]") 
							: this.renderAddButton("what", "action items")}</span>
					<br/><br/>
					<span type="requesters">Thanks for the help,<br/>
						<span className="link-to-input" onClick={(_) => this.requesters.focus()}>{ this.state.requester_names.join(', ').replace(/,(?=[^,]*$)/, ' and') }</span> </span>
				</p></div>}

			<div>
				<div style={{display: this.state.visibleHelperField === "deadline" && !this.props.is_performer ? "block":"none", background: this.state.visibleHelperField === "deadline" && !this.props.is_performer?"#ECEEF6":""}}>
					<Divider orientation="center">Upcoming tasks</Divider>

					<Row>
					{this.state.similarDeadlineTasks}
					</Row>
				</div>

				<div style={{display: this.state.visibleHelperField === "anticipated_time" && !this.props.is_performer ? "block":"none", background: this.state.visibleHelperField === "anticipated_time" && !this.props.is_performer?"#FCF4F0":""}}>
					<Divider orientation="center">
						<div style={{ whiteSpace: 'normal' }}>Previous task that took a similiar amount of time</div>
					</Divider>
					<Row>
					{this.state.similarTasks}
      
    				</Row>
					
				</div>
				<div style={{display: this.state.visibleHelperField === "what" && !this.props.is_performer ? "block":"none", background: this.state.visibleHelperField === "what" && !this.props.is_performer?"#E8F6F9":""}}>
					<Divider orientation="center">Link to related team documents</Divider>
					<Tree style={{background: this.state.visibleHelperField === "what" && !this.props.is_performer?"#E8F6F9":""}}
						blockNode
						treeData={this.state.treeData}
					/>
					{this.state.treeData.length === 0 && <span>No previous task with anticipated amount of completion time</span>}
				</div>
			</div>

			
			<div style={{marginTop: "30px", paddingLeft: "30px", paddingRight: "30px"}}>
				{!this.props.taskLighter && (this.props.is_performer ? 
					<span>{this.state.requester_names.map((n, i) => i===0 ? n : " & " + n)} asked you to take care of this task{this.props.why ? ' because ' + this.props.why.value + '' : ''}. Please determine the priority of the task based on your judgment.</span>
					:
					<span>If you want to communicate the importance of this task with {this.getPerformersNames() || "performers"}, use {this.renderAddButton('why') || <span className="link-to-input" onClick={(_) => this.why && this.why.focus()}>the why field</span>} instead.</span>
				)}
				<br/><br/>
				<Form.Item
					label="Priority"
					name="priority"
					hasFeedback
					help="👁️ Only visible to you"
				>
					<Select placeholder="Select the priority" defaultValue={this.props.is_performer ? this.props.performers[this.props.userID].priority : this.props.priority} style={{ width: 200 }} onChange={(e)=>  this.savePriority(e)}>
						<Option value="urgent">🚨 Urgent</Option>
						<Option value="high">⚠️ High</Option>
						<Option value="medium">👌 Medium</Option>
						<Option value="low">🤗 Low</Option>
					</Select>
				</Form.Item>
			</div>
        </div>
		<div id="sidebar-container"></div>
		</div>;
	}
}


ReactDOM.render(
	<Empty imageStyle={{visibility: 'hidden'}} description='' />,
		document.getElementById('taskview')
	);

export {TaskView};
