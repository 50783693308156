import React from 'react';
import ReactDOM from 'react-dom';
// import "./lib/bootstrap.css"; 
// import './App.css';
import { firebase_auth, firebase_instance } from "../utils";

import { Avatar, Button } from 'antd';

import {TaskListDropdown} from './TaskListDropdown'; 
import AuthSocialForm from './AuthSocialForm'; 

import { gapi } from 'gapi-script';

// import {firebase_auth, signInWithGoogle}  from './utils';

class TaskLightHome extends React.Component {
  constructor(props) {    
    super(props);

    this.state = {
      "signed_in": false
    }

    // gapi.load('client:auth2', this.initClient);
  }

  componentDidMount() {
    firebase_auth.onAuthStateChanged((user) => {
      if (user !== null) {
          this.setState({signed_in: true, email: firebase_instance.auth().currentUser.email});
      }
      else {
          this.setState({signed_in: false, email: ""});
      }
    })
  }


  /**
   *  Called when the signed in status changes, to update the UI
   *  appropriately. After a sign-in, the API is called.
   */
  updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      // Set the signed in user
    //   setSignedInUser(gapi.auth2.getAuthInstance().currentUser.je.Qt);
    //   setIsLoadingGoogleDriveApi(false);
      // list files if user is authenticated
      this.setState({signed_in: true, email: gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail()});
      
    } else {
      // prompt user to sign in
      // this.handleAuthClick();
    }
  };

  /**
   *  Sign in the user upon button click.
   */
  handleAuthClick = (event) => {
    gapi.auth2.getAuthInstance().signIn();
  };
  
  handleSignOutClick = (event) => {
    firebase_auth.signOut()

    this.setState({signed_in: false});
    if(document.getElementById('taskListView'))
      ReactDOM.unmountComponentAtNode(document.getElementById('tasklistview'));
    // TODO this.setState({"signed_in": false});
  };

  displayTaskListView = () => {
    if(document.getElementById('tasklistview')) {
      document.getElementById('tasklistview').style.display = 'block'; 
    }
  }

  render() {
    // if not logged in, ask gmail address 
    console.log('tasklight home rendered'); 
    this.displayTaskListView(); 
    return <div className="app">
      <nav className="navbar navbar-light bg-light">
      <button style={{border: "none", background: "none"}} className="navbar-brand" onClick={(e) => window.location.reload()}>
        <img src={window.location.origin + '/tasklight-logo.png'} width="200"/> 
      </button>
        <span className="navbar-text">
          Welcome!
          <Avatar style={{ marginLeft: "5px", marginRight: "5px", backgroundColor: '#f56a00', verticalAlign: 'middle' }} size="large" gap={4}>
           {/* replace with user initial */}
           <div id="initial"></div>
          </Avatar>
          {this.state.signed_in ?<Button onClick={this.handleSignOutClick}>Sign out</Button>: ""}
        </span>
      </nav>

      {/* if not logged in, ask gmail address */}
      {this.state.signed_in ? <TaskListDropdown email={this.state.email}/> : 
        <div style={{ marginTop:"100px", marginBottom: 16, width:"100%" }}>
          {/* <Button onClick={this.handleAuthClick}>Sign in with my Gmail account</Button> */}
          <AuthSocialForm/>
      </div>}
    </div>
  };
}	


export default TaskLightHome;