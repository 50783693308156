import React from 'react';

import "antd/dist/antd.css";

import { Button } from 'antd';

import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';


import "../tasklist.css"; 
import "../lib/bootstrap.css"; 

import { loadTask, STATUS, COLOR_STATUS }  from '../utils';

const StatusLabel = (props) => {
    let marginLeft = (30 * props.level) + 10; 
    return (
            props.isRequester ? 
            <div 
                className="statusLabel" 
                key={props.requester + "-" + props.performer}
                onClick={() => loadTask(props.taskID, props.userId, props.status, props.workStatus, props.isPerformer, props.files, props.requester, props.performer, props.taskLighter)}
            >
                {props.isSubtask && (
                    <b style={{marginLeft: `${marginLeft}px`}}></b>
                )}
                {/* <div className="nameLabel" 
                    style={{
                        background: props.userId === props.performer ? 'black' : 'lightgray', 
                        color: props.userId === props.performer ? 'white' : 'black'
                    }}>
                        {props.userId === props.performer ? 'Me' : props.userMap[props.performer].name}
                </div>
                <div className="statLabel" style={{color: COLOR_STATUS[props.status].text, backgroundColor: COLOR_STATUS[props.status].background}}>
                    {STATUS[props.status]}
                </div> */}
                <Button type={COLOR_STATUS[props.status].background== '#97c05c' ? "primary": "default"} shape="round">
                    {props.userId === props.performer ? <EditOutlined/> : props.userMap[props.performer].name} | {STATUS[props.status]}
                </Button>
            </div>
            : (props.isPerformer ? 
                <div>
                    {/* the performer's status label */}
                    <div 
                        className="statusLabel"
                        key={props.performer + "-" + props.requester}
                        onClick={() => loadTask(props.taskID, props.userId, props.status, props.workStatus, props.isPerformer, props.files, props.requester, props.performer, props.taskLighter)}
                    >
                        {props.isSubtask && (
                            <b style={{marginLeft: `${marginLeft}px`}}></b>
                        )}
                        {/* <div className="nameLabel" style={{background: 'black', color: 'white'}}>Me</div>
                        <div className="statLabel" style={{color: COLOR_STATUS[props.status].text, backgroundColor: COLOR_STATUS[props.status].background}}>
                            {STATUS[props.status]}
                        </div> */}

                        <Button type={COLOR_STATUS[props.status].background== '#97c05c' ? "primary": "default"} shape="round">
                            Me | {STATUS[props.status]}
                        </Button>
                    </div>
                    
                    {/* other performer's status labels */}
                    {Object.keys(props.task.performers).filter((id) => id !== props.userId).length > 0 ? 
                        <b style={{marginLeft: '50px'}}>Others: </b> : ''
                    }
                    
                    {Object.keys(props.task.performers).filter((id) => id !== props.userId).map((id, i) => 
                    {
                        let performerStatus = props.task.performers[id].status; 
                        return (<div 
                            className="statusLabel-others"                    >
                            {props.isSubtask && (
                                <b style={{marginLeft: `${marginLeft}px`}}></b>
                            )}
                            {/* <div className="nameLabel">{props.userMap[id].name}</div>
                            <div className="statLabel" style={{color: 'black', backgroundColor: 'lightgray'}}>
                                {STATUS[performerStatus]}
                            </div> */}
                            <Button shape="round" disabled>
                                {props.userMap[id].name} | {STATUS[performerStatus]}
                            </Button>
                        </div>)
                    })
                    }
                </div> : 
                // stakeholder status labels
                <div 
                    className="statusLabel-others"
                    onClick={() => loadTask(props.taskID, props.userId, props.status, props.workStatus, props.isPerformer, props.files, props.requester, props.performer, props.taskLighter)}                    >
                    {props.isSubtask && (
                        <b style={{marginLeft: `${marginLeft}px`}}></b>
                    )}
                    <Button type="default" shape="round">
                        <InfoCircleOutlined /> View
                    </Button>
                </div>
            )
    )
}

export {StatusLabel}