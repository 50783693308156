import React from 'react';
import ReactDOM from 'react-dom';

import "antd/dist/antd.css";

import "../tasklist.css"; 
import "../lib/bootstrap.css"; 

import "../logging.js"

import { Button, Checkbox, Divider, Radio } from 'antd';

import { ArrowRightOutlined, FileAddOutlined, UpOutlined } from '@ant-design/icons';
import { convertToFriendlyDate, createTask, getTaskInfo, getBackgroundColor, reloadTaskList, getWorkStatus, isBlockedOnSubtask, loadTask, priorityMap }  from '../utils';
import { TaskView } from './TaskView';
import { StatusLabel } from './StatusLabel';

let SORT_BY = "deadline";
let blockChecked = false; 
let completedChecked = true; 

const Task = (props) => {
    let bgColor = getBackgroundColor(props.task, props.userId, props.isRequester, props.isPerformer);
    if (props.subtasks) {
        let bool = isBlockedOnSubtask(props.userId, props.subtasks);
        if (bool) {
            bgColor = '#E8E8E8';
        }
    }

    // if this is self-assigned task that they are the performers then overwrite bg color with white
    if(props.isRequester && Object.keys(props.task.performers).length === 1 && Object.keys(props.task.performers)[0] === props.userId){
        bgColor = 'white';
    }
        

    return (
        <>
            <tr
                id={props.userId + "-task-" + props.taskID}
                className="mainTask"
                style={{
                    backgroundColor: bgColor, 
                    cursor: Object.keys(props.task.performers).length === 0 && (props.isPerformer || props.isRequester) ? 'pointer' : 'default',
                    display: ((props.completedTasks.includes(props.taskID) && completedChecked) || 
                              (props.blockedTasks.includes(props.taskID) && blockChecked)) ? 
                              'none' : 'table-row',
                }}
                onClick={() => Object.keys(props.task.performers).length === 0 && (props.isPerformer || props.isRequester) ? loadTask(props.taskID, props.userId, '', '', false, props.files, '', '', props.taskLighter) : ''}
            > 
                {props.isSubtask ? 
                    <td style={{width: '70%'}}>
                        <b style={{marginLeft: `${30*props.level}px`}}></b>
                        <ArrowRightOutlined />
                        <b style={{marginLeft: '10px'}}>{props.task.title}</b>
                        <i>{' requested by ' + props.task.requesters.map((id, i) => i === 0 ? (id === props.userId ? 'Me' : props.userMap[id].name) : ' ' + (id === props.userId ? 'Me' : props.userMap[id].name))}</i>
                    </td>
                : 
                    <td>
                        <b>{props.task.title}</b>
                        <i>{' requested by ' + props.task.requesters.map((id, i) => i === 0 ? (id === props.userId ? 'Me' : props.userMap[id].name) : ' ' + (id === props.userId ? 'Me' : props.userMap[id].name))}</i>
                    </td>
                }
                <td>{props.task.anticipated_time ? props.task.anticipated_time + ' mins' : ''}</td>
                <td>{convertToFriendlyDate(props.task.deadline)}</td>
                <td>{props.isPerformer ? priorityMap[props.task.performers[props.userId].priority] : priorityMap[props.task.priority]}</td>
            </tr>
            
            {/* if this is self-assigned task that they are the performers and no status update yet at the task */}
            {
                Object.keys(props.task.performers).length === 0 && props.task.requesters.includes(props.userId) && (
                    <tr id={props.userId + "-status-" + props.taskID} 
                    style={{
                        backgroundColor: bgColor, 
                        display: ((props.completedTasks.includes(props.taskID) && completedChecked) ||
                                  (props.blockedTasks.includes(props.taskID) && blockChecked)) ? 
                                  'none' : 'table-row'
                    }}>
                    <td 
                        className="status-label"
                        colSpan="4" 
                        style={{borderTop: "none"}} 
                    > 
                    <StatusLabel 
                        key={props.userId + '-' + props.taskID + '-' + props.userId}
                        taskID={props.taskID}
                        task={props.task}
                        userId={props.userId}
                        userMap={props.userMap}
                        isRequester={true}
                        isPerformer={false} // pretend they are not a performer to show requester view? 
                        requester={props.userId}
                        performer={props.userId}
                        status={"DRAFTING"}
                        workStatus={"DRAFTING"}
                        files={props.files}
                        isSubtask={props.isSubtask}
                        level={props.level}
                        taskLighter={props.taskLighter}
                    />
                    </td>
                </tr>
                )
            }
            {
                !Object.keys(props.task.performers).includes(props.userId) && !props.task.requesters.includes(props.userId) && ( // stakeholder items
                    <tr id={props.userId + "-status-" + props.taskID} 
                    style={{
                        backgroundColor: bgColor, 
                        display: ((props.completedTasks.includes(props.taskID) && completedChecked) ||
                                  (props.blockedTasks.includes(props.taskID) && blockChecked)) ? 
                                  'none' : 'table-row'
                    }}>
                    <td 
                        className="status-label"
                        colSpan="4" 
                        style={{borderTop: "none"}} 
                    > 
                    <StatusLabel 
                        key={props.userId + '-' + props.taskID + '-' + props.userId}
                        taskID={props.taskID}
                        task={props.task}
                        userId={props.userId}
                        userMap={props.userMap}
                        isRequester={false}
                        isPerformer={false} // pretend they are not a performer to show requester view? 
                        requester={props.userId}
                        performer={props.userId}
                        status={"WAITING_CLARIFICATION"} // they dont have status for this task since they are not requester or performer. Just put a status that does not require action 
                        workStatus={"WAITING_CLARIFICATION"}
                        files={props.files}
                        isSubtask={props.isSubtask}
                        level={props.level}
                        taskLighter={props.taskLighter}
                    />
                    </td>
                </tr>
                )
            }
            { Object.keys(props.task.performers).length > 0 && ( // STATUS LABEL CODE when there are performers for the task
                <tr id={props.userId + "-status-" + props.taskID} 
                    style={{
                        backgroundColor: bgColor, 
                        display: ((props.completedTasks.includes(props.taskID) && completedChecked) ||
                                  (props.blockedTasks.includes(props.taskID) && blockChecked)) ? 
                                  'none' : 'table-row'
                    }}>
                    <td 
                        className="status-label"
                        colSpan="4" 
                        style={{borderTop: "none"}} 
                    > 
                    {
                        props.isRequester ? (props.isPerformer ? // first case: when user is both performer + requester

                            Object.keys(props.task.performers).map( (id, i) => {
                                if (id === props.userId) { // when id is the user, generate special status label   
                                    let [requesterBlocked, performerStatus, requesterStatus, performerWorkStatus, requesterWorkStatus, pKeys] = getTaskInfo(props.task, props.userId, props.isRequester, props.isPerformer, id);
                                    return (
                                        <StatusLabel 
                                            key={props.userId + '-' + props.taskID + '-' + id}
                                            taskID={props.taskID}
                                            task={props.task}
                                            userId={props.userId}
                                            userMap={props.userMap}
                                            isRequester={true}
                                            isPerformer={false} // pretend they are not a performer to show requester view? 
                                            requester={props.userId}
                                            performer={props.userId}
                                            status={requesterStatus === 'LOOKS_GOOD' ? 'LOOKS_GOOD' : 'TODO'}
                                            workStatus={requesterStatus === 'LOOKS_GOOD' ? 'LOOKS_GOOD' : 'TODO'}
                                            files={props.files}
                                            isSubtask={props.isSubtask}
                                            level={props.level}
                                            taskLighter={props.taskLighter}
                                        />
                                    )
                                } else { // when id refers to other performers, generate separate status labels
                                    let [requesterBlocked, performerStatus, requesterStatus, performerWorkStatus, requesterWorkStatus, pKeys] = getTaskInfo(props.task, props.userId, props.isRequester, props.isPerformer, id);
                                    return (
                                        <StatusLabel 
                                            key={props.userId + '-' + props.taskID + '-' + id}
                                            taskID={props.taskID}
                                            task={props.task}
                                            userId={props.userId}
                                            userMap={props.userMap}
                                            isRequester={true}
                                            isPerformer={false} // pretend they are not a performer to show requester view?
                                            requester={props.userId}
                                            performer={id}
                                            status={requesterStatus}
                                            workStatus={requesterWorkStatus}
                                            files={props.files}
                                            isSubtask={props.isSubtask}
                                            level={props.level}
                                            taskLighter={props.taskLighter}
                                        />
                                    )
                                }        
                            })
                            : // second case: user is just requester 
                            Object.keys(props.task.performers).map( (id, i) => {
                                let [requesterBlocked, performerStatus, requesterStatus, performerWorkStatus, requesterWorkStatus, pKeys] = getTaskInfo(props.task, props.userId, props.isRequester, props.isPerformer, id);
                                return (
                                    <StatusLabel 
                                        key={props.userId + '-' + props.taskID + '-' + id}
                                        taskID={props.taskID}
                                        task={props.task}
                                        userId={props.userId}
                                        userMap={props.userMap}
                                        isRequester={props.isRequester}
                                        isPerformer={props.isPerformer}
                                        requester={props.userId}
                                        performer={id}
                                        status={requesterStatus}
                                        workStatus={requesterWorkStatus}
                                        files={props.files}
                                        isSubtask={props.isSubtask}
                                        level={props.level}
                                        taskLighter={props.taskLighter}
                                    />
                                )
                            })
                        )
                        : ( props.isPerformer ?                      
                            <StatusLabel 
                                    key={props.userId + '-' + props.taskID}
                                    taskID={props.taskID}
                                    task={props.task}
                                    userId={props.userId}
                                    userMap={props.userMap}
                                    isRequester={props.isRequester}
                                    isPerformer={props.isPerformer}
                                    requester={props.task.requesters}
                                    performer={props.userId}
                                    status={props.task.performers[props.userId].status}
                                    workStatus={getWorkStatus(props.task.performers[props.userId].workStatus, props.task.performers[props.userId].status, props.isPerformer)}
                                    files={props.files}
                                    isSubtask={props.isSubtask}
                                    level={props.level}
                                    taskLighter={props.taskLighter}
                                /> : // stakeholder
                                <StatusLabel 
                                    key={props.userId + '-' + props.taskID}
                                    taskID={props.taskID}
                                    task={props.task}
                                    userId={props.userId}
                                    userMap={props.userMap}
                                    isRequester={false}
                                    isPerformer={false}
                                    requester={props.task.requesters}
                                    performer={Object.keys(props.task.performers)}
                                    status={''}
                                    workStatus={''}
                                    files={props.files}
                                    isSubtask={props.isSubtask}
                                    level={props.level}
                                    taskLighter={props.taskLighter}
                                /> 
                        )
                    }
                    </td>
                </tr>
            )}

            { props.subtasks && (props.subtasks.map((subtask) => 
                {
                    return ( subtask.id && (
                        <Task 
                            key={props.userId + '-' + subtask.id}
                            taskID={subtask.id}
                            task={subtask.data()}
                            userId={props.userId}
                            userMap={props.userMap}
                            isRequester={subtask.data().requesters.includes(props.userId)}
                            isPerformer={subtask.data().performers ? props.userId in subtask.data().performers : false}
                            taskMap={props.taskMap}
                            subtasks={props.taskMap[subtask.id]}
                            isSubtask={true}
                            blockedTasks={props.blockedTasks}
                            completedTasks={props.completedTasks}
                            files={props.files}
                            level={props.level + 1}
                            taskLighter={props.taskLighter}
                        />)
                    )
                }
                ) )}
        </>
    ); 
}

class TaskListView extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {tasks: [], blockedTasks: [], completedTasks: [], 
            taskMap: {}, // map root task's id -> array of child tasks 
            loaded: false
        };
    }

    createTask() {
        // create the task in the DB and scroll down
        createTask(this.props.userId).then( task => {            
            // detach the previous task 
            if(document.getElementById('contentliquid'))
                ReactDOM.unmountComponentAtNode(document.getElementById('taskview'));
    
            ReactDOM.render(
                <div>
                    <div style={{textAlign: 'center'}}>
                        <Button type="text"onClick={() => reloadTaskList(this.props.userId)}><UpOutlined /> Return to Task List</Button>
                    </div>
                    <TaskView taskID={task.id} userID={this.props.userId} requesters={[this.props.userId]} performers={{}} status={"DRAFTING"} workStatus={"DRAFTING"} is_performer={false}  
                        userMap={this.props.userMap} files={this.props.files} resources={task.resources} requester={''} performer={''} location={[]} ActionItems={[]} taskLighter={this.props.taskLighter}/>
                </div>, document.getElementById('taskview')
            );
    
        }, alert);
    }

    // TODO what does async do for this function. Can we make this function keep gets called whenever there is a change in filter?
    componentDidMount() {
        this.appendTaskList();
    }

    appendTaskList = async () => {
        let map = {}; 

        let mainTasks = this.props.tasks.filter((t) => !(t.data().why && (t.data().why['task-id']))); // root tasks 
        if(SORT_BY == "deadline")
            mainTasks.sort(function(a, b) {
                var keyA = a.data().deadline || "2999-12-31", keyB = b.data().deadline || "2999-12-31";
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        else {// sort by priority 
            let self = this;
            mainTasks.sort(function(a, b) {
                let priorityVal = ['', 'low', 'medium', 'high', 'urgent'];
                
                var keyA = self.props.userId in a.data().performers ? priorityVal.indexOf(a.data().performers[self.props.userId].priority) : -1, 
                    keyB = self.props.userId in b.data().performers ? priorityVal.indexOf(b.data().performers[self.props.userId].priority) : -1;
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });
        }

        await this.setState({tasks: mainTasks});
        
        let subtasks = this.props.tasks.filter((s) => s.data().why && s.data().why['task-id']); 

        if (subtasks.length === 0) {
            this.setState({loaded: true});
        } else if (subtasks.length > 0) {
            let subtaskState = {};
            for (let s of subtasks) {
                let parent; 
                parent = s.data().why['task-id']; 

                // this.props.tasks: all the accociated tasks to users
                // subtask has parent and see if the user is involed
                if (this.props.tasks.map(t => t.id).includes(parent)) {
                    if (parent in map) {
                        map[parent].push(s); 
                    } else {
                        map[parent] = [s]; 
                    }

                    subtaskState['tasks'] = [...this.state.tasks];
                    
                } else { 
                    subtaskState["tasks"] = [...this.state.tasks, s]; // add subtask to main task list if user isn't performer/requester of parent task
                    map[s] = []; // have to map every task to something even if it's an empty array
                }
            }

            // sort subtasks based on order that stakeholders specified
            for (var parent in map) {
                let parentTask;
                if(this.props.tasks.filter(t => t.id === parent).length)
                    parentTask = this.props.tasks.filter(t => t.id === parent)[0].data();
                else continue;
                const sortingArray = parentTask.actionItems && parentTask.actionItems.length > 1 ? parentTask.actionItems[1].children.map(c => c.key):[];
                map[parent].sort((a, b) =>{  
                    return sortingArray.indexOf(a.id) - sortingArray.indexOf(b.id);
                });
            }

            subtaskState['taskMap'] = map;
            subtaskState['loaded'] = true;
            this.setState({tasks: subtaskState["tasks"], taskMap: subtaskState["taskMap"], loaded: subtaskState["loaded"]});
        }

        // figure out which tasks are blocked
        for (let task of this.props.tasks) {
            let bgColor = getBackgroundColor(task.data(), this.props.userId, task.data().requesters.includes(this.props.userId), this.props.userId in task.data().performers); 
            if (bgColor === '#E8E8E8') {
                // append new value to prev state
                this.setState(prevState => ({
                    blockedTasks: [...prevState.blockedTasks, task.id]
                })); 
            } 
        }

        // figure out which tasks are completed
        for (let task of this.props.tasks) {
            let isRequester = task.data().requesters.includes(this.props.userId);
            let isPerformer = this.props.userId in task.data().performers;
            let isCompleted = false; 
            if (isRequester) {
                let bool = false; 
                for (let p of Object.keys(task.data().performers)) {
                    let taskInfo = getTaskInfo(task.data(), this.props.userId, true, false, p); 
                    let reqWorkStatus = taskInfo[4]; 
                    if (reqWorkStatus !== "LOOKS_GOOD") {
                        bool = false; 
                        break;
                    } else {
                        bool = true; 
                    }
                };
                isCompleted = bool; 
            } else if (isPerformer) {
                let taskInfo2 = getTaskInfo(task.data(), this.props.userId, false, true); 
                let perfWorkStatus = taskInfo2[3]; 
                isCompleted = (perfWorkStatus === "LOOKS_GOOD" || perfWorkStatus === "COMPLETED"); 
            }
            if (isCompleted) {
                this.setState(prevState => ({
                    completedTasks: [...prevState.completedTasks, task.id]
                })); 
            } 
        } 
            }

    render() {
        if (this.state.loaded) {
        return (
            <div id="taskListView" key={this.props.userMap[this.props.userId].name + 'TaskListView'}>
                <h1 style={{margin: '10px', marginBottom: '20px'}}>{this.props.userMap[this.props.userId].name}'s Task List</h1>
                <Checkbox checked={blockChecked} onChange={(e) => {blockChecked=e.target.checked; this.appendTaskList()}}>Hide tasks you are blocked on</Checkbox> 
                <Checkbox checked={completedChecked} onChange={(e) => {completedChecked=e.target.checked; this.appendTaskList()}}>Hide completed tasks</Checkbox> 
                <div style={{float: "right"}}>
                    <label style={{marginRight: "5px"}}>Sort by </label>
                    <Radio.Group defaultValue={SORT_BY} buttonStyle="solid" onChange={(e) => {SORT_BY=e.target.value;this.appendTaskList();}}>
                        <Radio.Button value="deadline">Deadline</Radio.Button>
                        <Radio.Button value="priority">Priority</Radio.Button>
                    </Radio.Group>
                </div>
                <table className="center table borderless" style={{marginTop: '15px'}}>
                    <thead>
                        <tr>
                        <th>Task</th>
                        <th style={{minWidth: "200px"}}>{this.props.taskLighter ? "":"Est. Completion"}</th>
                        <th>{this.props.taskLighter ? "":"Deadline"}</th>
                        <th style={{minWidth: "100px"}}>Priority</th>
                        </tr>
                    </thead>

                    <tbody style={{width: '100%', display: 'table-row-group'}}>                 
                    {this.state.tasks.map(task => 
                        <Task 
                            key={this.props.userId + '-' + task.id}
                            taskID={task.id}
                            task={task.data()}
                            userId={this.props.userId}
                            userMap={this.props.userMap}
                            isRequester={task.data().requesters.includes(this.props.userId)}
                            isPerformer={task.data().performers ? this.props.userId in task.data().performers : false}
                            taskMap={this.state.taskMap}
                            subtasks={this.state.taskMap[task.id]}
                            isSubtask={false}
                            blockedTasks={this.state.blockedTasks}
                            completedTasks={this.state.completedTasks}
                            files={this.props.files}
                            level={0}
                            taskLighter={this.props.taskLighter}
                        />
                        )} 
                    </tbody>
                </table>
                
                <Divider />

                <Button className="create-request-btn" onClick={() => this.createTask()} type="default" icon={<FileAddOutlined />}  size={'large'}>
                    Create a new request
                </Button>
                
            </div>
        )} else {
            return (<h1>Loading...</h1>)
        }
    }
}

export {TaskListView}