import React from 'react';

import { Button, Input, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import {loadTask, setData, getStatusInfo}  from '../utils';
import {lmdd} from "../lib/lmdd";

const { TextArea } = Input;

class TodoList extends React.Component {
	constructor(props) {
    	super(props);
		this.state ={items: this.props.items};
		console.log('todolist props: ', props);
		console.log('todolist state: ', this.state);
	}
	

	
	
  render () {
	  	// if the sub-task is created by someone else, then it shouldn't let the current user edit the sub task
		var t = (this.props.items.filter(w => w.stakeholders?.length) || []).map((item, index) => {
			return (
				<li id={item["task-id"]} className={"list-group-item lmdd-block " + (item.task ? "sub-task": "")} style={{background: item.blocked ? 'gray': null}}>
					<div> 
						<span className="drag-handle" style={{float:"left", display: this.props.hide  || (item.requesters?.indexOf(this.props.userID) < 0) ? "none": "block"}}>&#9776;</span>
							<TextArea autoSize={{ minRows: 1, maxRows: 5 }} onKeyUp={(e) => this.onChangeName(e, item["task-id"])} defaultValue={item.value} style={{width: "70%", float:"left", "text-decoration": item.done ? "line-through" : ""}} type="text" bordered={false} key={Math.random()}/>
							{this.renderLink(item)}
						<div style={{display: this.props.hide  || (item.requesters?.indexOf(this.props.userID) < 0) ? "none": "block"}}>
							{/* <Switch checkedChildren="sub-task" onClick={(e) => this.onClickDone(index)} style={{float:"left", "margin-top": "3px"}} checked={item.task} /> */}
							<Button style={{float:"right"}} type="default" className="close" onClick={(e)=> this.onClickClose(item["task-id"])}>&times;</Button>
						</div>
					</div>
				</li>
		)}
		);

		var private_tasks = (this.props.is_performer ?
			<ul id="private_container" className="list-group" style={{minHeight: "40px", marginTop: 0}}>
				{(this.props.items.filter(w => (!w.stakeholders || w.stakeholders?.length == 0)) || []).map((item, index) =>
					{
						return (
							<li id={item["task-id"]} className={"list-group-item lmdd-block sub-task"} style={{background: item.blocked ? 'gray': null}}>
								<div> 
									<span className="drag-handle" style={{float:"left", display: this.props.hide ? "none": "block"}}>&#9776;</span>
										<TextArea autoSize={{ minRows: 1, maxRows: 5 }} onKeyUp={(e) => this.onChangeName(e, index)} defaultValue={item.value} style={{width: "70%", float:"left", textDecoration: item.done ? "line-through" : ""}} type="text" bordered={false} key={Math.random()}/>
										{this.renderLink(item)}
									<div style={{display: this.props.hide ? "none": "block"}}>
										{/* <Switch checkedChildren="sub-task" onClick={(e) => this.onClickDone(index)} style={{float:"left", "margin-top": "3px"}} checked={item.task} /> */}
										<Button style={{float:"right"}} type="default" className="close" onClick={(e)=> this.onClickClose(item["task-id"])}>&times;</Button>
									</div>
								</div>
							</li>
						)}
					)}
			</ul>: "");

		return (<div> 
				<ul className="list-group"> <li className="list-group-item"><TodoForm addItem={this.props.addItem} /></li>
					{this.props.is_performer ? <li style={{marginBottom: "24px"}}>🙋 visible to {this.props.parentRequesterName}: this would help {this.props.parentRequesterName} to know that you have blockers, so they Can plan out their request</li>: ""}
					{t}
				</ul>
				{this.props.is_performer ?
					<ul style={{marginBottom: "0px", backgroundColor: "#E8F5E9", marginTop: "20px", paddingLeft: "5px"}}>
						<li>👁️ only visible to you: not ready or too personal to share with {this.props.parentRequesterName}</li>
					</ul>
					: ""
				}
				{private_tasks}
			</div>
    );
  }
}
  
class TodoListItem extends React.Component {
  constructor(props) {
    super(props);
    this.onClickClose = this.onClickClose.bind(this);
		this.onClickDone = this.onClickDone.bind(this);
		this.state ={item: this.props.item};
  }
  onChangeName(e) {
	var index = parseInt(this.props.index);
	this.props.changeName(index, e.target.value);
  }
  onClickClose() {
    var index = parseInt(this.props.index);
    this.props.removeItem(index);
  }
  onClickDone() {
	var index = parseInt(this.props.index);
    this.props.markTodoDone(index);
  }
  render () {
    var todoClass = this.props.item.done ? "done" : "undone";
	const loadPerfTaskView = (e) => {
		let status = ''; 
		let workStatus = ''; 
		let isSubPerf = (e["key"] === this.props.userID && Object.keys(this.props.item['performers'] || {}).includes(this.props.userID));
		if (isSubPerf) { // performer
			let pInfo = getStatusInfo(this.props.item['performers'] || {}, this.props.userID, false, true);
			status = pInfo[0]; 
			workStatus = pInfo[2]; 
		} else { // requester
			let rInfo = getStatusInfo(this.props.item['performers'] || {}, this.props.userID, true, false, e["key"]); 
			status = rInfo[1]; 
			workStatus = rInfo[3];
		}
		loadTask(this.props.item["task-id"], this.props.userID, status, workStatus, isSubPerf, this.props.files, this.props.item['requesters'], e["key"]);
	}

	let go_to_subtask = <span></span>;

	// if the current user is one of requesters of the parent task, the user get to visit any performer view
	if(this.props.item["requesters"]?.indexOf(this.props.userID) > -1) {
		if(Object.keys(this.props.item["performers"] || {}).length) { // if there are performers
			const perfMenu = (
				<Menu onClick={loadPerfTaskView}>
					{(Object.keys(this.props.item['performers'] || {})).map((id) => 
						<Menu.Item key={id}>
							{this.props.userMap[id].name}
						</Menu.Item>
					)}
				</Menu>);

			go_to_subtask= <Dropdown overlay={perfMenu}>
				<Button className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', float: 'right', marginRight: '5px'}} onClick={e => e.preventDefault()}>
				Choose performer <DownOutlined />
				</Button>
			</Dropdown>
		} else if(Object.keys(this.props.item["performers"] || {}).length == 1) { // if there is only one put the button instead of dropdown
			const performerID = Object.keys(this.props.item['performers'])[0];
			go_to_subtask = <button className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', color: 'white', float: 'right', marginRight: '5px', cursor: 'pointer'}} onClick={e => {e.preventDefault();loadPerfTaskView({"key":performerID});} }>
			{this.props.userMap[ performerID ].name}
			</button>;
		} 
		else { // no performers, but user is a requester
			go_to_subtask= <button className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', color: 'white', float: 'right', marginRight: '5px', cursor: 'pointer'}} onClick={e => {e.preventDefault();loadPerfTaskView({"key":''});} }>
				View
			</button>;
		}
	}

	// if not, they can only visit their view since they are a performer
	else {
		if(Object.keys(this.props.item["performers"] || {}).indexOf(this.props.userID) > -1) {
			go_to_subtask= <button className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', color: 'white', float: 'right', marginRight: '5px', cursor: 'pointer'}} onClick={e => {e.preventDefault();loadPerfTaskView({"key":this.props.userID});} }>
				View
			</button>;
		}
	}
	
    return(
      <li className={"list-group-item lmdd-block " + (this.props.item.task ? "sub-task": "")}>
				<div className={todoClass}>
					<span className="drag-handle" style={{float:"left", display: this.props.hide ? "none": "block"}}>&#9776;</span>
							<Input onKeyUp={(e) => this.onChangeName(e)} value={this.props.item.value || "[Untitled]"} style={{width: "70%", float:"left"}} type="text" bordered={false}/>
					{go_to_subtask}
        </div>
      </li>     
    );
  }
}

class TodoForm extends React.Component {
  constructor(props) {
	super(props);
	this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
  }
  onSubmit(event) {
    event.preventDefault();

    var newItemValue = this.refs.itemName.value;
    
    if(newItemValue) {
	  this.props.addItem({newItemValue});
	  this.formRef.reset();
    }
  }
  render () {
    return (
      <form ref={(el) => this.formRef = el} onSubmit={this.onSubmit} className="form-inline">
        <input type="text" style={{"border": 0}} ref="itemName" className="form-control" placeholder="add a new sub-task..."/>
        <Button onClick={this.onSubmit} type="default" style={{float: 'right'}}>Add</Button> 
      </form>
    );   
  }
}

class TodoApp extends React.Component {
  constructor (props) {
		super(props);
		this.addItem = this.addItem.bind(this);
		this.removeItem = this.removeItem.bind(this);
		this.markTodoDone = this.markTodoDone.bind(this);
        this.todoItems = this.props.initItems;
        
        this.state = {...this.props, ...{}
        }
	}
	
	componentDidUpdate() {
		// alert("re-rendered")
	}
    componentDidMount() {//set up LMDD and add an event listener
        
      let el = this.refs.lmdd;// document.getElementById('taskview')

      lmdd.set(el, {
        containerClass: 'list-group',
        draggableItemClass: 'lmdd-block',
        handleClass: 'drag-handle',
        dataMode: true
	});
			
			el.addEventListener('lmddend', this.handleDragEvent.bind(this));
			el.addEventListener('lmddstart', this.showContainer.bind(this));
	}
	
	showContainer(e) {
		// alert("")
		var containers = document.getElementsByClassName("list-group");
		for (var i = 0; i < containers.length; i++) {
			containers[i].style.border = "4px dotted black";
		}		
	}

  handleDragEvent(e){
	// hide container
	var containers = document.getElementsByClassName("list-group");
	for (var i = 0; i < containers.length; i++) {
		containers[i].style.border = "";
	}

	if(e.detail.from.container != e.detail.to.container) {	
		if (!this.props.is_performer) {
			throw "Assertion failed";
        }

		var newIndex = e.detail.to.index - 2;
		var oldIndex = e.detail.from.index - 1;

		const sortedItems = [...this.props.initItems];
		debugger;

		var target_item;
		for(var i in sortedItems){
			if(sortedItems[i]["task-id"] == e.detail.draggedElement.id){
				// take out an element
				target_item = sortedItems.splice(i, 1)[0];
				break; 
			}
		}

		// // find the next public item and put it right before it
		// var keyToFind = target_item["task-id"];
		// var prev_item_id = null;
		// for(var i in e.detail.to.container.children){
		// 	if(e.detail.to.container.children[i].id == keyToFind){
		// 		prev_item_id = e.detail.to.container.children[i-1] ? e.detail.to.container.children[i-1].id : null;
		// 		break;
		// 	}
		// }

		// if(!prev_item_id)
		// 	sortedItems.splice(0, 0, target_item);

		// else 
		// 	for(var i in sortedItems){
		// 		if(sortedItems[i]["task-id"] == prev_item_id){
		// 			// Add an element to the dictionary
		// 			sortedItems.splice(i+1, 0, target_item);
		// 			break;
		// 		}
		// 	}

		// if(e.detail.to.container.id == "private_container") {
		// 	//  set stakeholders to empty
		// 	target_item["stakeholders"] = [];

		// 	// insert into private list 

		// 	setData({"stakeholders": []}, "tasks", keyToFind);

		// } else {
		// 	// add the requester of the parent task to stakeholders
		// 	target_item["stakeholders"] = this.props.parentRequesters;

		// 	setData({"stakeholders": this.props.parentRequesters}, "tasks", keyToFind);

		// 	// remove from public list at oldIndex
		// }

        this.props.moveSubtask(sortedItems);
        // this.setState({"initItems": sortedItems});
	}

	// move within a container
	else 
		if (e.detail.dragType === 'move') {
			const sortedItems = [];

			for(let i in document.querySelectorAll(".list-group li")){
				debugger;
				let element_id = document.querySelectorAll(".list-group li")[i].id;
				if(!element_id) continue;
				
				sortedItems.push(this.props.initItems.filter(t => t["task-id"] == element_id)[0])

			}
			this.props.moveSubtask(sortedItems, true);
        }
        
        
  }
  addItem(todoItem) {
		let items = [...this.props.initItems];

		items.push({
			index: this.props.initItems.length+1, 
			value: todoItem.newItemValue, 
			done: false, 
			task: true
		});

        this.props.addSubTask(todoItem.newItemValue, items.length - 1);
        

	}
	
  changeName = (task_id, new_name) => {
		let items = [...this.props.initItems];
		let target_index;

		for(var i in items){
			if(items[i]["task-id"] == task_id){
				target_index = i;
				break; 
			}
		}

		setData({"title": new_name}, "tasks", task_id)

		items[target_index]["value"] = new_name;
		this.props.renderWhat(items);
  }

  removeItem (task_id) {
		this.props.deleteSubTask(task_id);
  }
  markTodoDone(itemIndex) {
		var todo = this.props.initItems[itemIndex];
    // todoItems.splice(itemIndex, 1);
		todo.task = !todo.task;

		if(todo.task) {
			// add as sub-task
			this.props.addSubTask(todo.value, itemIndex);
		}
		else {
			// delete the sub-task
			this.props.deleteSubTask(itemIndex);
		}
  }

    renderLink = (item) => {
        const loadPerfTaskView = (e) => {
            let status = ''; 
            let workStatus = ''; 
            let isSubPerf = (e["key"] === this.props.userID && Object.keys(item['performers'] || {}).includes(this.props.userID));
            if (isSubPerf) { // is performer of subtask
                let pInfo = getStatusInfo(item['performers'] || {}, this.props.userID, false, true);
                status = pInfo[0]; 
                workStatus = pInfo[2]; 
            } else { // requester
                let rInfo = getStatusInfo(item['performers'] || {}, this.props.userID, true, false, e["key"]); // pick the 1st performer to show task view of
                status = rInfo[1]; 
                workStatus = rInfo[3];
            }
            loadTask(item["task-id"], this.props.userID, status, workStatus, isSubPerf, this.props.files, item['requesters'], e["key"]);
        }

        let single_view = <button className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', color: 'white', float: 'right', marginRight: '5px', cursor: 'pointer'}} onClick={e => {e.preventDefault();loadPerfTaskView({"key":this.props.userID});} }>
                View
            </button>;

        // if the current user is one of requesters of the sub-task, the user get to visit any performer view
        if(item["requesters"]?.indexOf(this.props.userID) > -1) {
            if(Object.keys(item["performers"] || {}).length > 1) 	{ // if there are performers
                const perfMenu = (
                    <Menu onClick={loadPerfTaskView}>
                        {(Object.keys(item['performers'] || {})).map((id) => 
                            <Menu.Item key={id}>
                                {this.props.userMap[id].name}
                            </Menu.Item>
                        )}
                    </Menu>);

                return <Dropdown overlay={perfMenu}>
                            <button className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', color: 'white', float: 'right', marginRight: '5px'}} onClick={e => e.preventDefault()}>
                            Choose performer <DownOutlined />
                            </button>
                    </Dropdown>;
            }
            else if(Object.keys(item["performers"] || {}).length == 1) { // if there is only one put the button instead of dropdown
                const performerID = Object.keys(item['performers'])[0];
                return <button className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', color: 'white', float: 'right', marginRight: '5px', cursor: 'pointer'}} onClick={e => {e.preventDefault();loadPerfTaskView({"key":performerID});} }>
                Open task
                </button>;
            }
            else return <button className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', color: 'white', float: 'right', marginRight: '5px', cursor: 'pointer'}} onClick={e => {e.preventDefault();loadPerfTaskView({"key":''});} }>
                        View
                        </button>; // no performers, but user is a requester
        }

        // if not, they can only visit their view when they are the performer
        else {
            if(Object.keys(item["performers"] || {}).indexOf(this.props.userID) > -1)
                return single_view;
        }	

        return <span></span>;
    }

  render() {
    // if the sub-task is created by someone else, then it shouldn't let the current user edit the sub task
    var t = (this.props.initItems.filter(w => w.stakeholders?.length) || []).map((item, index) => {
        return (
            <li id={item["task-id"]} className={"list-group-item lmdd-block " + (item.task ? "sub-task": "")} style={{background: item.blocked ? 'gray': null}}>
                <div> 
                    <span className="drag-handle" style={{float:"left", display: this.props.hide  || (item.requesters?.indexOf(this.props.userID) < 0) ? "none": "block"}}>&#9776;</span>
                        <TextArea autoSize={{ minRows: 1, maxRows: 5 }} onKeyUp={(e) => this.changeName(item["task-id"], e.target.value)} defaultValue={item.value} style={{width: "70%", float:"left", "text-decoration": item.done ? "line-through" : ""}} type="text" bordered={false} key={Math.random()}/>
                        {this.renderLink(item)}
                    <div style={{display: this.props.hide  || (item.requesters?.indexOf(this.props.userID) < 0) ? "none": "block"}}>
                        {/* <Switch checkedChildren="sub-task" onClick={(e) => this.onClickDone(index)} style={{float:"left", "margin-top": "3px"}} checked={item.task} /> */}
                        <Button style={{float:"right"}} type="default" className="close" onClick={(e)=> this.removeItem(item["task-id"])}>&times;</Button>
                    </div>
                </div>
            </li>
        )}
    );
        
    //   alert("re-rendered");
    var private_tasks = (this.props.is_performer ?
        <ul id="private_container" className="list-group" style={{minHeight: "40px", marginTop: 0}}>
            {(this.props.initItems.filter(w => (!w.stakeholders || w.stakeholders?.length == 0)) || []).map((item, index) =>
                {
                    return (
                        <li id={item["task-id"]} className={"list-group-item lmdd-block sub-task"} style={{background: item.blocked ? 'gray': null}}>
                            <div> 
                                <span className="drag-handle" style={{float:"left", display: this.props.hide ? "none": "block"}}>&#9776;</span>
                                    <TextArea autoSize={{ minRows: 1, maxRows: 5 }} onKeyUp={(e) => this.changeName(item["task-id"], e.target.value)} defaultValue={item.value} style={{width: "70%", float:"left", textDecoration: item.done ? "line-through" : ""}} type="text" bordered={false} key={Math.random()}/>
                                    {this.renderLink(item)}
                                <div style={{display: this.props.hide ? "none": "block"}}>
                                    <Button style={{float:"right"}} type="default" className="close" onClick={(e)=> this.removeItem(item["task-id"])}>&times;</Button>
                                </div>
                            </div>
                        </li>
                    )}
                )}
        </ul>: "");
        
    return (
      <div ref="lmdd" style={{"marginLeft": "30px", "display": "inline"}}>
        <TodoForm addItem={this.addItem} />
        <ul className="list-group"> <li className="list-group-item"><TodoForm addItem={this.props.addItem} /></li>
            {this.props.is_performer ? <li style={{marginBottom: "24px"}}>🙋 visible to {this.props.parentRequesterName}: this would help {this.props.parentRequesterName} to know that you have blockers, so they Can plan out their request</li>: ""}
            {t}
		</ul>
        {this.props.is_performer ?
					<ul style={{marginBottom: "0px", backgroundColor: "#E8F5E9", marginTop: "20px", paddingLeft: "5px"}}>
						<li>👁️ only visible to you: not ready or too personal to share with {this.props.parentRequesterName}</li>
					</ul>
					: ""
				}
        {private_tasks}
        {/* <TodoList userMap={this.props.userMap} parentRequesterName={this.props.parentRequesterName} userID={this.props.userID} is_performer={this.props.is_performer} items={this.state.initItems} addItem={this.addItem} changeName={this.changeName} removeItem={this.removeItem} markTodoDone={this.markTodoDone} files={this.props.files}/>       */}
      </div>
    );
  }
}

export {TodoListItem, TodoApp};