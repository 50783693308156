import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Button, Dropdown, Menu, Tree } from 'antd';
import { DownOutlined, WarningOutlined } from '@ant-design/icons';
import _ from "lodash";
import $ from 'jquery';

import {getStatusInfo, loadTask}  from '../utils';

// formatted tree data
let t = [];

class ActionItems extends React.Component {
  state = {
    gData: [{children: this.props.privateItems, key: "private", title: "private"}, {children: this.props.publicItems, key: "public", title: "public"}],
    // gData:treeData,
  };

  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(([key, val]) =>
      prevProps[key] !== val && console.log(`Prop '${key}' changed`, val)
    );
    if (this.state) {
      Object.entries(this.state).forEach(([key, val]) =>
        prevState[key] !== val && console.log(`State '${key}' changed`)
      );
    }

    $(`.action-items-requester .ant-tree-treenode`).show();

    // hide private items for requesters
    if(this.props.draggable) 
      $([...Array(this.props.items[0].children.length+2).keys() ].map((x) => `.action-items-requester .ant-tree-treenode:nth-of-type(${x+1})`).join(', ')).hide();

    // stakeholders view - hide only private items that is not theirs
    else {
      const not_myItem = this.props.items[0].children.filter(c => !c.requesters.includes(this.props.userID) && !c.performers.includes(this.props.userID)).map((x) => `.action-items-requester .ant-tree-treenode:nth-of-type(${x+1})`);
      if(not_myItem.length)
        $(not_myItem.join(', ')).hide();
    }
    
  }

  onDrop = info => {
    const dropKey = info.node.key; // the drag node should be inserted after this key
    const dragKey = info.dragNode.key; // the node that got moved
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const prev_data = _.cloneDeep(t);
    const data = [...t];
    const private_items_cnt = t[0].children.length;

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    // check if it is this user's item. If not, check where it ended it up go
    const is_myItem = dragObj.requesters.includes(this.props.userID);

    if (!info.dropToGap 
        && !info.node.test  
    ) {
        // Drop on the content or drop at the first place 
        loop(data, dropKey, item => {
          item.children = item.children || [];
          // where to insert 
          item.children.unshift(dragObj);
        });
      } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    // when not an item owner tries to move item to private to public or the other way
    if(private_items_cnt !== data[0].children.length && !is_myItem){
      this.props.moveSubtask(prev_data);
      return;
    }
      
    if(data.length === 3 ) {
      // for the case they move the item at the root level
      if(data[0].key === "private" && data[2].key === "public") {
        const new_private = data.splice(1, 1);
        data[0].children.push(new_private[0]);   
      }
      else {
        this.props.moveSubtask(prev_data);
        return;
      }
    }
    this.props.moveSubtask(data);
  };

  renderLink = (item) => {
    const loadPerfTaskView = (e) => {
        let status = ''; 
        let workStatus = ''; 
        let isSubPerf = (e["key"] === this.props.userID && Object.keys(item['performers'] || {}).includes(this.props.userID));
        if (isSubPerf) { // is performer of subtask
            let pInfo = getStatusInfo(item['performers'] || {}, this.props.userID, false, true);
            status = pInfo[0]; 
            workStatus = pInfo[2]; 
        } else { // requester
            const perf_view = item['performers']
            let rInfo = getStatusInfo(item['performers'] || {}, this.props.userID, true, Object.keys(item['performers'] || {}).includes(this.props.userID), e["key"]); // pick the 1st performer to show task view of
            status = rInfo[1]; 
            workStatus = rInfo[3];
        }
        loadTask(item["key"], this.props.userID, status, workStatus, isSubPerf, this.props.files, item['requesters'], e["key"]);
    }

    let single_view = <Button type="link" className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', float: 'right', marginRight: '5px', cursor: 'pointer'}} onClick={e => {e.preventDefault();loadPerfTaskView({"key":this.props.userID});} }>
            View
        </Button>;

    console.log(item);  
    // if the current user is one of requesters of the sub-task, the user get to visit any performer view
    if(item["requesters"]?.indexOf(this.props.userID) > -1) {
        if(Object.keys(item["performers"] || {}).length) 	{ // if there are performers
            if(item["status"] == "WAITING_DRAFT") {
                const performerID = Object.keys(item['performers'])[0];
                return <Button type="link" className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', marginRight: '5px', cursor: 'pointer'}} onClick={e => {e.preventDefault();loadPerfTaskView({"key":performerID});} }>
                <WarningOutlined /> Complete draft and send to performers
                </Button>;
            }     
            else if(Object.keys(item["performers"] || {}).length == 1) { // if there is only one put the button instead of dropdown
                const performerID = Object.keys(item['performers'])[0];
                return <Button type="link" className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', marginRight: '5px', cursor: 'pointer'}} onClick={e => {e.preventDefault();loadPerfTaskView({"key":performerID});} }>
                Assigned to {this.props.userMap[performerID].name}
                </Button>;
            }

            const perfMenu = (
                <Menu onClick={loadPerfTaskView}>
                    {(Object.keys(item['performers'] || {})).map((id) => 
                        <Menu.Item key={id}>
                            {this.props.userMap[id].name}
                        </Menu.Item>
                    )}
                </Menu>);

            return <Dropdown overlay={perfMenu}>
                        <Button type="link" className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none', float: 'right', marginRight: '5px'}} onClick={e => e.preventDefault()}>
                        Choose performer <DownOutlined />
                        </Button>
                </Dropdown>;
            
        }
        
        else {return <Button type="link" className="ant-dropdown-link" style={{fontSize: 'small', border: 'none', background: 'none',  marginRight: '5px', cursor: 'pointer'}} onClick={e => {e.preventDefault();loadPerfTaskView({"key":''});} }>
                    Assign performers
                    </Button>;
                } // no performers, but user is a requester
    }

    // if not, they can only visit their view when they are the performer
    else {
        if(Object.keys(item["performers"] || {}).indexOf(this.props.userID) > -1)
            return single_view;
    }	

    return <span></span>;
}

  render() {
      if(this.props.items.length &&  !_.isEqual(_.sortBy(t), _.sortBy(this.props.items))) {
        t = this.props.items.length? _.cloneDeep(this.props.items):[];
        t[0].children = t[0].children.map(item => {
            let i = _.cloneDeep(item);
            i["test"] = item.title;
            i["title"] = <span>{item.title} {this.renderLink(item)}
            {i["requesters"].includes(this.props.userID) ? 
                <Button style={{float:"right"}} type="default" className="close" onClick={(e)=> this.props.deleteSubTask(item.key)}>&times;</Button>
                : ""}
            </span>;
            return i});
        t[1].children = t[1].children.map(item => {
            let i = _.cloneDeep(item);
            i["test"] = item.title;
            i["title"] = <span>{item.title} {this.renderLink(item)}
            {i["requesters"].includes(this.props.userID) ? 
                <Button style={{float:"right"}} type="default" className="close" onClick={(e)=> this.props.deleteSubTask(item.key)}>&times;</Button>
                : ""}
            </span>;
            return i});
      }
      

    return (    
      <Tree
        className="draggable-tree"
        defaultExpandedKeys={['private', 'public']}
        draggable={this.props.draggable}
        blockNode
        onDrop={this.onDrop}
        treeData={t}
        allowDrop={(element, to) => { 
          if(to)
              console.log(to);
          // console.log('allowDrop - from', element.parent.data); 
          // console.log('allowDrop - to', to.parent.data); 
          return true; } }
      />
    );
  }
}

export {ActionItems};