import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { TaskView } from './components/TaskView';
import { showTaskList, TEAM_FOLDER_ID, TEAM_MEMBERS } from './components/TaskListDropdown'; 

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { Avatar, Button, Empty, Input, Popconfirm } from 'antd';
import { SendOutlined, UpOutlined, QuestionCircleOutlined} from '@ant-design/icons';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const firebase_instance = firebase;
const firebase_auth = firebase.auth();
const firestore = firebase.firestore();


// list of all the possible status in the system and their mapping of user-friendly text 
const STATUS = {
    DRAFTING: 'Drafting',
    WAITING_DRAFT: 'Draft to be sent',
    ACCEPT_OR_REJECT: 'Accept or Reject?',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
    BACKLOGGED: 'Backlogged',
    TODO: 'Todo',
    WAITING_CLARIFICATION: 'Waiting for clarification',
    WAITING_RES: 'Waiting for response',
    COMPLETED:'Completed',
    ASSIGN_TO_OTHER: 'Assign task to other performer',
    WATCHING: 'Watching',
    ANSWER: 'Answer question/suggestion',
    REQUEST_UPDATE: 'Waiting updates',
    REQUESTED_UPDATE: 'Reqeust updates on your progress',
    REVIEW: 'Review work', 
    LOOKS_GOOD: 'Completed',
    SENT_CHAT: 'Sent chat', 
    HAVE_CHAT: 'You have new message(s)',
    REVISE: 'Revise task',
    SENT_BACK: 'Sent back'
}

const COLOR_STATUS = {
    DRAFTING: {text: 'white', background: '#97c05c'},
    WAITING_DRAFT: {text: 'white', background: 'gray'},
    ACCEPT_OR_REJECT: {text: 'white', background: '#97c05c'},
    ACCEPTED: {text: 'black', background: 'lightgray'},
    REJECTED: {text: 'black', background: 'lightgray'},
    BACKLOGGED: {text: 'white', background: 'gray'},
    TODO: {text: 'white', background: '#97c05c'},
    WAITING_CLARIFICATION: {text: 'white', background: 'gray'},
    WAITING_RES: {text: 'white', background: 'gray'},
    COMPLETED: {text: 'black', background: 'lightgray'},
    ASSIGN_TO_OTHER: {text: 'white', background: '#97c05c'},
    WATCHING: {text: 'white', background: '#97c05c'},
    ANSWER: {text: 'white', background: '#97c05c'},
    REQUEST_UPDATE: {text: 'black', background: 'lightgray'},
    REQUESTED_UPDATE: {text: 'white', background: '#97c05c'},
    REVIEW: {text: 'white', background: '#97c05c'},
    LOOKS_GOOD: {text: 'black', background: 'lightgray'},
    SENT_CHAT: {text: 'black', background: 'lightgray'}, 
    HAVE_CHAT: {text: 'white', background: '#97c05c'},
    REVISE: {text: 'white', background: '#97c05c'},
    SENT_BACK: {text: 'white', background: '#lightgray'}
}

let performerStatusMap = {
    WAITING_DRAFT: {requesterStatus: "DRAFTING", requesterBlocked: false}, 
    ACCEPT_OR_REJECT: {requesterStatus: "WAITING_RES", requesterBlocked: true}, 
    ACCEPTED: {requesterStatus: 'ACCEPTED', requesterBlocked: false}, 
    REJECTED: {requesterStatus: "ASSIGN_TO_OTHER", requesterBlocked: false}, 
    BACKLOGGED: {requesterStatus: "WAITING_RES", requesterBlocked: true},
    TODO: {requesterStatus: "WATCHING", requesterBlocked: false}, 
    WAITING_CLARIFICATION: {requesterStatus: "ANSWER", requesterBlocked: false}, 
    COMPLETED: {requesterStatus: "REVIEW", requesterBlocked: false}, 
    ANSWER: {requesterStatus: "WAITING_CLARIFICATION", requesterBlocked: true}, 
    REQUESTED_UPDATE: {requesterStatus: "REQUEST_UPDATE", requesterBlocked: true},
    LOOKS_GOOD: {requesterStatus: "LOOKS_GOOD", requesterBlocked: false}, 
    SENT_CHAT: {requesterStatus: "HAVE_CHAT", requesterBlocked: false}, 
    HAVE_CHAT: {requesterStatus: "SENT_CHAT", requesterBlocked: false},
    REVISE: {requesterStatus: "SENT_BACK", requesterBlocked: false}
}

let statusPrompt = {
    WAITING_DRAFT: (p, r) => `${r} is preparing to send this task.`,
    DRAFTING: (p, r) => `You haven't sent the request yet.`,
    WAITING_CLARIFICATION: (p, r) => 'You asked questions regarding tasks to ' + ((r !== null) ? r : p) + '. They will respond to you shortly.',
    WAITING_RES: (p, r) => `You have sent your request and are waiting for a response from ${p} `,
    ACCEPT_OR_REJECT: (p, r) => `${r} sent you this request. To update the status, review and choose one of the following options.`, 
    ACCEPTED: (p, r) => (r !== null) ? `You've agreed to help ${r}. You can ask questions to ${r} regarding the task using the suggestion feature or chat.` : `${p} has agreed to help you.`,
    BACKLOGGED: (p, r) => 'You have backlogged this task and must respond to it later.',
    ANSWER: (p, r) => ((r !== null) ? r : p ) + ' has asked you a question or made a suggestion regarding the task.',
    COMPLETED: (p, r) => (r !== null) ? `You've marked this task as complete. ${r} will now review your work.` : `${p} has marked this task as complete. Please review their work.`,
    LOOKS_GOOD: (p, r) => (r !== null) ? `${r} thanks you for your work!` : `You have approved the work done and marked it as complete.`,
    SENT_CHAT: (p, r) => (r !== null) ? `You sent chat message(s) to ${r}.` : `You sent chat message(s) to ${p}.`,
    HAVE_CHAT: (p, r) => (r !== null) ? `You have new chat message(s) from ${r}.` : `You have new chat message(s) from ${p}.`,
    REQUEST_UPDATE: (p, r) => `You have asked ${p} for their update`,
    REQUESTED_UPDATE: (p, r) => (r !== null) ? `${r} was wondering about your progress on this request` : `You have asked ${p} for their update`,
    REVISE: (p, r) => (r !== null) ? `${r} would like you to revise this task. Please check the chat for feedback.` : ``,
    SENT_BACK: (p, r) => (r !== null) ? `` : `You have sent the task back to ${p}.`,
};

let priorityMap = {
    urgent: '🚨 Urgent', 
    high: '⚠️ High',
    medium: '👌 Medium',
    low: '🤗 Low',
    '': '',
}

/* changes the status of the task (and work status depending on what the new status is) */
function changeTaskStatus(taskID, performerID, new_status) {
    // new_status is requester state, convert it to a corresponding performer state
    if( ![ ...Object.keys(performerStatusMap) ].includes(new_status) ) {
        for (const s in performerStatusMap) {
            if(performerStatusMap[s].requesterStatus === new_status) {
                new_status = s;
                break;
            }
        }
    }
    
    setData({
        [`performers.${performerID}.status`]: new_status 
    }, "tasks", taskID);

    // all the following statuses only affect the user's status, not their work status
    // so there is a check that the new status is not any of these statuses
    // if it is not, then only change the work status
    if (!["WAITING_CLARIFICATION", "ANSWER", "SENT_CHAT", "HAVE_CHAT", "WAITING_RES", "DRAFTING", "REVIEW", "ASSIGN_TO_OTHER", "WATCHING"].includes(new_status)) {
        setData({
            [`performers.${performerID}.workStatus`]: new_status 
        }, "tasks", taskID)
    }
}

/* changes the work status of a task (depending on what the new status is) */
function changeTaskWorkStatus(taskID, performerID, new_status) {
    // same reasoning as comments in changeTaskStatus function
    if (!["WAITING_CLARIFICATION", "ANSWER", "SENT_CHAT", "HAVE_CHAT", "WAITING_RES", "DRAFTING", "REVIEW", "ASSIGN_TO_OTHER", "WATCHING"].includes(new_status)) {
        setData({
            [`performers.${performerID}.workStatus`]: new_status 
        }, "tasks", taskID)
    }
}

/* obtains the correct work status of the user 
   first if clause is for performers
   second if clause is for requesters
   otherwise the original status is returned
*/
const getWorkStatus = (old_status, new_status, isPerformer) => {
    if (isPerformer) {
        if (!["WAITING_CLARIFICATION", "ANSWER", "SENT_CHAT", "HAVE_CHAT", "WAITING_RES", "DRAFTING", "REVIEW", "ASSIGN_TO_OTHER", "WATCHING"].includes(new_status)) {
            return new_status; 
        } else {
            return old_status; 
        }
    }
    else if (!["WAITING_CLARIFICATION", "ANSWER", "SENT_CHAT", "HAVE_CHAT"].includes(new_status)) {
        return new_status; 
    } else {
        return old_status; 
    }
}

/* returns the status and work statuses of the requester and performer given task & user information
   task information = task performers, userId, whether user is requester/performer
   for a task to exist, it doesn't need to have performers defined (hence why perf is defaulted to undefined)
   note: perf will also only be a parameter for when user is a requester; 
         if user is a performer, then there's no need to pass in that parameter
*/
const getStatusInfo = (performers, userId, isRequester, isPerformer, perf=undefined) => { 
    let requesterStatus = ''; 
    let performerStatus = ''; 
    let pKeys = Object.keys(performers || {}); 
    let requesterWorkStatus = ''; 
    let performerWorkStatus = ''; 
    
    if (isRequester) {
        if (pKeys.length > 0) {
            let pInfo; 
            if (perf !== undefined) { // if there are performers defined for the task
                pInfo = performers[perf]; 
                requesterStatus = performerStatusMap[pInfo.status].requesterStatus;
                requesterWorkStatus = getWorkStatus(performerStatusMap[pInfo.workStatus].requesterStatus, requesterStatus, isPerformer); 
            } 
        }       
    } else if (isPerformer) {
        let performer = performers[userId]; 
        performerStatus = performer.status; 
        performerWorkStatus = getWorkStatus(performer.workStatus, performerStatus, isPerformer); 
    } 
    // note: if the user is a requester and there are no performers defined, 
    // then performerStatus and performerWorkStatus are simply empty strings
    return [performerStatus, requesterStatus, performerWorkStatus, requesterWorkStatus]; 
}

/* returns task information 
        - whether requester is blocked on task
        - performer and requester statuses and work statuses
        - task performers 
   note: perf will also only be a parameter for when user is a requester; 
         if user is a performer, then there's no need to pass in that parameter
*/
const getTaskInfo = (task, userId, isRequester, isPerformer, perf=undefined) => { // pass in perf when user is requester
    let requesterStatus = ''; 
    let requesterBlocked = '';
    let performerStatus = ''; 
    let pKeys = Object.keys(task.performers || {}); 
    let requesterWorkStatus = ''; 
    let performerWorkStatus = ''; 
    
    if (isRequester) {
        if (pKeys.length > 0) {
            let pInfo; 
            if (perf !== undefined) {
                pInfo = task.performers[perf]; 
                requesterStatus = performerStatusMap[pInfo.status].requesterStatus;
                requesterWorkStatus = getWorkStatus(performerStatusMap[pInfo.workStatus || "WAITING_DRAFT"].requesterStatus, requesterStatus, isPerformer); 
                requesterBlocked = performerStatusMap[pInfo.status].requesterBlocked; 
            } 
            // else {
            //     return 'should have passed in performer';  
            // }
        } 
        // else {
        //     return null;
        // }        
    } else if (isPerformer) {
        let performer = task.performers[userId]; 
        performerStatus = performer.status; 
        performerWorkStatus = getWorkStatus(performer.workStatus, performerStatus, isPerformer); 
    } 
    // else {
    //     return null; 
    // }
    return [requesterBlocked, performerStatus, requesterStatus, performerWorkStatus, requesterWorkStatus, pKeys]; 
}

/* returns what color the task row should be 
   gray if requester or performer is blocked
   white otherwise
*/
const getBackgroundColor = (task, userId, isRequester, isPerformer) => {
    if (isRequester) { 
        let requesterBlocked = false;
        Object.keys(task.performers).map((p) => {
            let pInfo = task.performers[p];
            let blocked = performerStatusMap[pInfo.status].requesterBlocked;
            if (blocked) {
                requesterBlocked = blocked; 
            }
        });
        if (requesterBlocked) {
            return '#E8E8E8'; 
        } else {
            return 'white'; 
        }
    } else if (isPerformer) {
        let performerStatus = task.performers[userId].status; 
        if (['WAITING_DRAFT', 'REJECTED', 'BACKLOGGED', 'WAITING_CLARIFICATION', 'WAITING_RES'].includes(performerStatus)) {
            return '#E8E8E8'; 
        } else {
            return 'white'; 
        }
    } else {
        return 'white';
    }
}

/* returns boolean for whether user is blocked on a subtask for a task */
const isBlockedOnSubtask = (userId, subtasks) => {
    for (let st of subtasks) {
        if (st.id) {
            let sData = st.data(); 
            let subtaskIsRequester = sData.requesters.includes(userId); 
            let subtaskIsPerformer = sData.performers ? userId in sData.performers : false;
            let color; 
            color = getBackgroundColor(sData, userId, subtaskIsRequester, subtaskIsPerformer)
            if (color === '#E8E8E8') {
                return true;
            }
        }
    }
    return false;
}

//  return date user-friendly e.g., today, tomorrow ..
function convertToFriendlyDate(date_string) {
    if(!date_string) return "";
    let [y,m,d] = date_string.split("-")

    let task_date = new Date(y, m - 1, d);
    let today = new Date();                 
    let tomorrow = new Date();
    tomorrow.setDate(new Date().getDate()+1);
    let yesterday = new Date();        
    yesterday.setDate(new Date().getDate()-1);

    let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    
    if (task_date.setHours(0,0,0,0) === today.setHours(0,0,0,0))
      return "Today";
    else if (task_date.setHours(0,0,0,0) === tomorrow.setHours(0,0,0,0))
      return "Tomorrow";
    else if (task_date.setHours(0,0,0,0) === yesterday.setHours(0,0,0,0))
      return "Yesterday";

    return `${days[task_date.getDay()]}, ${m}/${d}`;
}

function createData(data, entity_type, entityID) {
    firebase.firestore().collection(entity_type).doc(entityID).set(
       data, { merge: true })
    .catch((error) => {
        // The document probably doesn't exist.
        // debugger;
        alert("Error updating document: ", error);
    });
    
} 

function createTask(requesterID, more_data={}) {
    return new Promise((resolve, reject) => {
        let newRef = firebase.firestore().collection("tasks").add({...{
            requesters: [requesterID],
            performers: {},
            actionItems: [{children: []}, {children: []}]
        }, ...more_data})
        
        if(newRef) {
            resolve(newRef);
        }
        else {
            reject("The write operation failed");
        }
    });
}

function deleteField(field_names, entity_type, entityID) {
    var ref = firebase.firestore().collection(entity_type).doc(entityID);

    var to_delete = {};
    field_names.map(f => {
        to_delete[f] = firebase.firestore.FieldValue.delete();
    })

    // console.log(to_delete);

    ref.update(to_delete);
}

function deleteTask(taskID) {
    return new Promise((resolve, reject) => {
        let newRef = firebase.firestore().collection("tasks").doc(taskID).delete();

        // TODO delete child tasks
        
        // TODO delete the message doc / chat    
   

        if(newRef) {
            resolve(newRef);
        }
        else {
            reject("The write operation failed");
        }
    });
}

/*
    Insert data at the save_at location
 */
function setData(data, entity_type, entityID) {
    firebase.firestore().collection(entity_type).doc(entityID).update(
       data
    , { merge: true })
    .catch((error) => {
        // The document probably doesn't exist.
        // debugger;
        console.log(error); 
        alert("Error updating document: ", error);
    });
    
} 

// function setData(data, entity_type, entityID) {
//     firebase.firestore().collection(entity_type).doc(entityID).update(data)
//         .then(() => {
//             console.log("Document successfully written!");
//         })
//         .catch((error) => {
//             console.error("Error writing document: ", error);
//         });
// }

function getData(entity_type, field_to_search, op=null, entityID=null) {
    return new Promise((resolve, reject) => {
        let newRef = firebase.firestore().collection(entity_type);

        if (op)
            newRef = newRef.where(field_to_search, op, entityID).get();
        else
            newRef = newRef.doc(field_to_search).get()

        if(newRef) {
            resolve(newRef);
        }
        else {
            reject("The write operation failed");
        }
    });
}

function getUser(userId) {
    getData("users", userId)
}

function getTask(taskId) {
    return getData("tasks", taskId)
}

function getTeamUsers(teamID) {
    return getData("users", "team", "==", teamID)
}

function getSuggestion(taskId) {
    return getData("suggestion", taskId)
}


/*
    Get all the tasks associated to the given user
*/
async function getTaskList(userId) {
    const tasks_performers = await getData("tasks", `performers.${userId}.priority`, "in", ["", "urgent", "high", "medium", "low"]);
    const tasks_requesters = await getData("tasks", 'requesters', "array-contains", userId);
    
    // union of performers & requesters
    const d = tasks_performers.docs.concat(tasks_requesters.docs.filter((item) => tasks_performers.docs.map(x=> x.id).indexOf(item.id) < 0));
    const already_loaded = d.map(d => d.id);

    const stakeholders_ids = [...new Set(d.filter((t) => (t.data().why && t.data().why['task-id'])).map(t => t.data().why['task-id']))];

    // only loaded the one that is not loaded
    const e = stakeholders_ids.filter(id => !already_loaded.includes(id));
    if(e.length) {
        const tasks_stakeholders = await getData(`tasks`, firebase_instance.firestore.FieldPath.documentId(), "in", 
            e.splice(0,10));

        return [...d, ...tasks_stakeholders.docs];
    }
    
    return d;
}

/** new method added by Stuti
     *  works for parsing urls like `/team/[team name]`
     */
function getUrlParameter2 (sParam) {
    var sPathURL = window.location.pathname.substring(1), 
        sURLVariables = sPathURL.split('/'); 
    if (sURLVariables[0] === sParam) {
        return sURLVariables[1] === undefined ? true : sURLVariables[1]; 
    }
}


/*
	Load the given task and attach UI
 */
function loadTask(taskID, userID, status, workStatus, is_performer, files, req='', perf='', taskLighter=false) {
    ReactDOM.unmountComponentAtNode(document.getElementById('tasklistview'));
    console.log("is performer: ", is_performer);
	// load the task information 
    // console.log("work Status: ", workStatus); 
	getTask(taskID).then( snapshot => {
		let task = snapshot.data();

        // detach the previous task 
		if(document.getElementById('contentliquid'))
            ReactDOM.unmountComponentAtNode(document.getElementById('taskview'));

            ReactDOM.render(
                <div>
                    <div style={{textAlign: 'center'}}>
                        <Button type="text"onClick={() => reloadTaskList(userID)}><UpOutlined /> Return to Task List</Button>
                    </div>
                    <TaskView taskID={taskID} userID={userID} status={status} workStatus={workStatus} is_performer={is_performer} title={task.title || ""} location={task.location} deadline={task.deadline} performers={task.performers} requesters={task.requesters} anticipated_time={task.anticipated_time}
                        why={task.why} what={task.what} userMap={TEAM_MEMBERS} requester={req} performer={perf} files={files} location={task.location || []} actionItems={task.actionItems} taskLighter={taskLighter} priority={task.priority}/>
                </div>,
                    document.getElementById('taskview')
            );
	}, alert);
}

function reloadTaskList(userID) {
    ReactDOM.unmountComponentAtNode(document.getElementById('tasklistview'));
    showTaskList(userID, TEAM_FOLDER_ID); 
    ReactDOM.unmountComponentAtNode(document.getElementById('taskview'));
    document.getElementById('root').scrollIntoView({
    });
}

/*
    Similiar to setData() but this function creates a new key automatically
 */
function pushData() {
    var newPostKey = firebase.database().ref().push().key;
    
      // Write the new post's data simultaneously in the posts list and the user's post list.
      var updates = {};
      updates['/posts/' + newPostKey] = "postData";
      // updates['/user-posts/' + "uid" + '/' + newPostKey] = "postData";
    
    console.log(firebase.database().ref().update(updates));   
}


function ChatWrapper(props) {
    
    return (
        <div className="chat">
          <section>
            <ChatRoom status={props.status} is_performer={props.is_performer} sendRegularChat={props.sendRegularChat} askClarification={props.askClarification} uid={props.userID} TEAM_MEMBERS={props.TEAM_MEMBERS} taskID={props.taskID} taskLighter={props.taskLighter}/>
          </section>
        </div>
    );
}

const signInWithGoogle = (email) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
        'login_hint': email
      });
    firebase.auth().signInWithRedirect(provider).catch(function(error) {
      console.log("Error signing user in anonymously:", error);
    });
    // auth.signInWithPopup(provider);
 }

    
function SignIn(email) {
    
    
    
    return (
        <>
          <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
          <p>Do not violate the community guidelines or you will be banned for life!</p>
        </>
    )
    
}
    
function ChatRoom(props) {
    const dummy = useRef();
    const messagesRef = firestore.collection(`messages-${props.taskID}`);//.doc();

    const query = messagesRef.orderBy('createdAt').limitToLast(10);
    
    const [messages] = useCollectionData(query, { idField: 'id' });
    
    const [formValue, setFormValue] = useState('');

    const [isWaitingClarification, setStatus] = useState(props.status == "WAITING_CLARIFICATION");

    useEffect(() => {
        document.querySelector('.chat main').scrollTop = 1000;
    });

    
    const sendMessage = async (e) => {
        e.preventDefault();

        if(!formValue) return;
    
        if( document.getElementById('quote-container').textContent ) {
            await messagesRef.add({
                text: document.getElementById('quote-container').textContent,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid: props.uid,
                quote: true,
                field: document.getElementById('quote-container').getAttribute("field")
              })

            document.getElementById('quote-container').textContent = ""
        }

        await messagesRef.add({
          text: formValue,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.uid
        })
    
        setFormValue('');
        dummy.current.scrollIntoView({ behavior: 'smooth' });
    }

    const sendMessageAsClarification = async (e) => {
        setStatus(true);
        sendMessage(e); 
        props.askClarification(); 
    }

    const sendMessageAsRegularChat = async (e) => {
        sendMessage(e); 
        props.sendRegularChat(); 
    }

    const handleKeyPress = (e) => {
        if (e.charCode == 13) { // enter
            sendMessageAsRegularChat(e);
            return false;
          }
          return true;
      }

    return (<>
        <main>
            {props.status === "DRAFTING" && 
                <Empty
                    description={
                    <span>
                        This request is still a <b>draft</b>. Make sure to send the request to performer(s) to start chatting with them! 
                    </span>
                    }
                >
                </Empty>
            }
            
            {messages && messages.map(msg => msg.quote ? <Quote field={msg.field} text={msg.text} /> :
                <ChatMessage createdAt={msg.createdAt} key={msg.id} message={msg} uid={props.uid} TEAM_MEMBERS={props.TEAM_MEMBERS} />)}
            <span ref={dummy}></span>
    
        </main>

        <form style={{display: 'inline-flex', width: '-webkit-fill-available'}} >
            <span id="quote-container" style={{borderLeft: "5px solid grey", color: "grey"}} defaultValue="" />
    
            <Input disabled={props.status === "DRAFTING"} onKeyPress={isWaitingClarification || props.taskLighter? handleKeyPress : null} defaultValue="" value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Ask questions to your teammates" />

            {isWaitingClarification || props.taskLighter ? 
                <Button onClick={(e) => sendMessageAsRegularChat(e)} disabled={!formValue}><SendOutlined /></Button>
                :
                <Popconfirm title="Send message as" icon={<QuestionCircleOutlined />} okText="Call for action/clarification" cancelText="No action required" onCancel={sendMessageAsRegularChat} onConfirm={sendMessageAsClarification}>
                    <Button htmlType="submit" disabled={!formValue}><SendOutlined /></Button>
                </Popconfirm>
            }
             
             {/* <Dropdown.Button overlay={menu}><SendOutlined /></Dropdown.Button> */}
        </form>
      </>)
}
    
    
function ChatMessage(props) {
    const { text, uid, photoURL } = props.message;
    
    const messageClass = uid === props.uid ? 'sent' : 'received';
    
    const D = props.createdAt ? new Date(props.createdAt.toDate()) : null;

    return (<>
        <div className={`message ${messageClass}`}>
            <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{props.TEAM_MEMBERS[uid].name.substring(0,1)}</Avatar>
            <p>{text}</p>
            <span style={{color:"gray"}}>{D ? convertToFriendlyDate(`${D.getFullYear()}-${D.getMonth() + 1}-${D.getDate()}`) + ` ${D.getHours()}:${D.getMinutes()}`:"now"}</span>
        </div>
    </>)
}

function Quote(props) {
    return (<>
        <p style={{ color: 'gray', textAlign: 'left' }} href={props.field}>{props.text}</p>
    </>)
}

function insertQuote(field, value) {
    document.getElementById("quote-container").textContent = `${field}: ${value}`;
    document.getElementById("quote-container").setAttribute("field", field);
}

export {createData, createTask, convertToFriendlyDate, deleteField, deleteTask, ChatWrapper, changeTaskStatus, changeTaskWorkStatus, firebase_auth, getUrlParameter2, insertQuote, loadTask, setData, signInWithGoogle, pushData, getBackgroundColor, getTask, getTaskInfo, getSuggestion, getTaskList, getTeamUsers, reloadTaskList, getWorkStatus, isBlockedOnSubtask, getStatusInfo, performerStatusMap, statusPrompt, STATUS, COLOR_STATUS, priorityMap};
